import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
// import { validate } from 'valid-gtin';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { addLocale } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { TreeSelect } from 'primereact/treeselect';
import { TabView, TabPanel } from 'primereact/tabview';

import store from '../../../store/store';

import { Input200px, Input155px, Input100px, Input400px } from '../../../styles/inputGroup';
import { displayErrors, converteData, formataDataMoment } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';

import * as actions from '../../../store/modules/auth/actions';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';
// eslint-disable-next-line import/no-cycle
import ConsultaProduto from './consultaProduto';
import ConsultaGrupo from '../grupo/consultaGrupo';
import ConsultaSubGrupo from '../subGrupo/consultaSubGrupo';

export default function CadastroProduto({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);
  const cnpjEmpresaProps = get(payloadCadastro, 'cnpjEmpresa', '');

  const [desabilitarCadastroProduto, setDesabilitarCadastroProduto] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [empresaAliquotaPis, setEmpresaAliquotaPis] = useState(0);
  const [empresaAliquotaCofins, setEmpresaAliquotaCofins] = useState(0);
  const [registro, setRegistro] = useState(0);
  const [descricao, setDescricao] = useState('');
  const [descricaoResumida, setDescricaoResumida] = useState('');
  const [codigoEan, setCodigoEan] = useState('');
  const [referencia, setReferencia] = useState('');
  const [codigoSimilar, setCodigoSimilar] = useState('');
  const [mensagemNfe, setMensagemNfe] = useState('');
  const [unidadeMedida, setUnidadeMedida] = useState(0);
  const [unidadeMedidaVenda, setUnidadeMedidaVenda] = useState(0);
  const [showConsultaGrupo, setShowConsultaGrupo] = useState(false);
  const [idGrupo, setIdGrupo] = useState('');
  const [descricaoGrupo, setDescricaoGrupo] = useState('');
  const [codigoGrupo, setCodigoGrupo] = useState('');
  const [showConsultaSubGrupo, setShowConsultaSubGrupo] = useState(false);
  const [idSubGrupo, setIdSubGrupo] = useState('');
  const [descricaoSubGrupo, setDescricaoSubGrupo] = useState('');
  const [codigoSubGrupo, setCodigoSubGrupo] = useState('');
  const [cfopVenda, setCfopVenda] = useState('');
  const [cstVenda, setCstVenda] = useState('');
  const [csosnVenda, setCsosnVenda] = useState('');
  const [origemMercadoria, setOrigemMercadoria] = useState(0);
  const [icmsAliquota, setIcmsAliquota] = useState(0);
  const [icmsAliquotaFcp, setIcmsAliquotaFcp] = useState(0);
  const [icmsAliquotaSt, setIcmsAliquotaSt] = useState(0);
  const [icmsPRedBc, setIcmsPRedBc] = useState(0);
  const [icmsPRedBcSt, setIcmsPRedBcSt] = useState(0);
  const [icmsModalidadeBc, setIcmsModalidadeBc] = useState(0);
  const [icmsModalidadeBcSt, setIcmsModalidadeBcSt] = useState(0);
  const [precoCusto, setPrecoCusto] = useState(0);
  const [precoCustoReal, setPrecoCustoReal] = useState(0);
  const [precoVenda, setPrecoVenda] = useState(0);
  const [precoVenda2, setPrecoVenda2] = useState(0);
  const [precoVenda3, setPrecoVenda3] = useState(0);
  const [precoVenda4, setPrecoVenda4] = useState(0);
  const [precoVenda5, setPrecoVenda5] = useState(0);
  const [precoPromocao, setPrecoPromocao] = useState(0);
  const [dataInicioPromocao, setDataInicioPromocao] = useState(null);
  const [dataFimPromocao, setDataFimPromocao] = useState(null);
  const [qtdeAtacado2, setQtdeAtacado2] = useState(0);
  const [qtdeAtacado3, setQtdeAtacado3] = useState(0);
  const [qtdeAtacado4, setQtdeAtacado4] = useState(0);
  const [qtdeAtacado5, setQtdeAtacado5] = useState(0);
  const [localizacao, setLocalizacao] = useState('');
  const [localizacao2, setLocalizacao2] = useState('');
  const [localizacao3, setLocalizacao3] = useState('');
  const [localizacao4, setLocalizacao4] = useState('');
  const [localizacao5, setLocalizacao5] = useState('');
  const [aceitarDesconto, setAceitarDesconto] = useState(true);
  const [percentualDescontoMaximo, setPercentualDescontoMaximo] = useState(0);
  const [percentualComissao, setPercentualComissao] = useState(0);
  const [percentualComissaoMecanico, setPercentualComissaoMecanico] = useState(0);
  const [marca, setMarca] = useState('');
  const [tamanho, setTamanho] = useState('');
  const [cor, setCor] = useState('');
  const [comprimento, setComprimento] = useState(0);
  const [largura, setLargura] = useState(0);
  const [altura, setAltura] = useState(0);
  const [pesoBruto, setPesoBruto] = useState(0);
  const [pesoLiquido, setPesoLiquido] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [dataCadastro, setDataCadastro] = useState(null);
  const [ativo, setAtivo] = useState(true);
  const [aplicacao, setAplicacao] = useState('');
  const [codigoFabrica, setCodigoFabrica] = useState('');
  const [ncm, setNcm] = useState('');
  const [cest, setCest] = useState('');
  const [genero, setGenero] = useState('');
  const [codLst, setCodLst] = useState('');
  const [mva, setMva] = useState(0);
  const [mvaSt, setMvaSt] = useState(0);
  const [qtdePorCx, setQtdePorCx] = useState(0);
  const [ipiEntradaCst, setIpiEntradaCst] = useState('');
  const [ipiEntradaAliquota, setIpiEntradaAliquota] = useState(0);
  const [ipiSaidaCst, setIpiSaidaCst] = useState('');
  const [ipiSaidaAliquota, setIpiSaidaAliquota] = useState(0);
  const [tipo, setTipo] = useState('00');
  const [extIpi, setExtIpi] = useState('');
  const [teclaBalanca, setTeclaBalanca] = useState(0);
  const [ctaEntrada, setCtaEntrada] = useState('');
  const [ctaSaida, setCtaSaida] = useState('');
  const [ctaInventario, setCtaInventario] = useState('');
  const [ctaNr, setCtaNr] = useState('');
  const [descricaoComplementarNr, setDescricaoComplementarNr] = useState('');
  const [pisCofinsEntradaCst, setPisCofinsEntradaCst] = useState('');
  const [pisEntradaAliquota, setPisEntradaAliquota] = useState(0);
  const [cofinsEntradaAliquota, setCofinsEntradaAliquota] = useState(0);
  const [pisCofinsSaidaCst, setPisCofinsSaidaCst] = useState('');
  const [pisSaidaAliquota, setPisSaidaAliquota] = useState(0);
  const [cofinsSaidaAliquota, setCofinsSaidaAliquota] = useState(0);
  const [naturezaReceita, setNaturezaReceita] = useState('');
  const [indProp, setIndProp] = useState(0);
  const [qtdeIdealDeposito, setQtdeIdealDeposito] = useState(0);
  const [qtdeIdealPrateleira, setQtdeIdealPrateleira] = useState(0);
  const [qtdeMaximaDeposito, setQtdeMaximaDeposito] = useState(0);
  const [controlarEstoque, setControlarEstoque] = useState(true);
  const [estampariaProducao, setEstampariaProducao] = useState(true);
  const [estampariaManga, setEstampariaManga] = useState(true);
  const [estampariaMangaAcabamento, setEstampariaMangaAcabamento] = useState(true);
  const [estampariaGola, setEstampariaGola] = useState(true);
  const [estampariaGolaAcabamento, setEstampariaGolaAcabamento] = useState(true);
  const [estampariaBolso, setEstampariaBolso] = useState(true);
  const [fornecedorPrincipal, setFornecedorPrincipal] = useState('');
  const [pprUnidadeMedida, setPprUnidadeMedida] = useState(0);
  const [pprPrecoVenda, setPprPrecoVenda] = useState(0);
  const [pprPrecoVenda2, setPprPrecoVenda2] = useState(0);
  const [pprPrecoVenda3, setPprPrecoVenda3] = useState(0);
  const [pprPrecoVenda4, setPprPrecoVenda4] = useState(0);
  const [pprPrecoVenda5, setPprPrecoVenda5] = useState(0);
  const [pprFatorConversao, setPprFatorConversao] = useState(0);
  const [peaCodigoEan, setPeaCodigoEan] = useState('');
  const [peaAtivo, setPeaAtivo] = useState(true);
  const [pinIdProdutoIngrediente, setPinIdProdutoIngrediente] = useState(0);
  const [pinDescricaoProdutoIngrediente, setPinDescricaoProdutoIngrediente] = useState('');
  const [pinQuantidade, setPinQuantidade] = useState(0);
  const [listaPrecoUnidade, setListaPrecoUnidade] = useState([]);
  const [selectedPrecoUnidade, setSelectedPrecoUnidade] = useState(null);
  const [showCadastroPrecoUnidade, setShowCadastroPrecoUnidade] = useState(false);
  const [listaEan, setListaEan] = useState([]);
  const [selectedEan, setSelectedEan] = useState(null);
  const [showCadastroEan, setShowCadastroEan] = useState(false);
  const [listaIngrediente, setListaIngrediente] = useState([]);
  const [selectedIngrediente, setSelectedIngrediente] = useState(null);
  const [showCadastroIngrediente, setShowCadastroIngrediente] = useState(false);
  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [listaUnidadeMedida, setListaUnidadeMedida] = useState([]);
  const [listaUnidadeMedidaVenda, setListaUnidadeMedidaVenda] = useState([]);
  const [listaContaAnalitica, setListaContaAnalitica] = useState([]);
  const [listaTipoProduto, setListaTipoProduto] = useState([]);
  const [listaGeneroProduto, setListaGeneroProduto] = useState([]);
  const [listaIpiEntrada, setListaIpiEntrada] = useState([]);
  const [listaIpiSaida, setListaIpiSaida] = useState([]);
  const [listaPisCofinsEntrada, setListaPisCofinsEntrada] = useState([]);
  const [listaPisCofinsSaida, setListaPisCofinsSaida] = useState([]);
  const [nodeEstampariaCor, setNodeEstampariaCor] = useState([]);
  const [nodeEstampariaTamanho, setNodeEstampariaTamanho] = useState([]);
  const [nodeEstampariaModelo, setNodeEstampariaModelo] = useState([]);
  const [nodeEstampariaMalha, setNodeEstampariaMalha] = useState([]);
  const [nodeEstampariaManga, setNodeEstampariaManga] = useState([]);
  const [nodeEstampariaMangaAcabamento, setNodeEstampariaMangaAcabamento] = useState([]);
  const [nodeEstampariaGola, setNodeEstampariaGola] = useState([]);
  const [nodeEstampariaGolaAcabamento, setNodeEstampariaGolaAcabamento] = useState([]);
  const [nodeEstampariaBolso, setNodeEstampariaBolso] = useState([]);
  const [nodeEstampariaSilk, setNodeEstampariaSilk] = useState([]);
  const [nodeEstampariaSublimacao, setNodeEstampariaSublimacao] = useState([]);
  const [nodeEstampariaBordado, setNodeEstampariaBordado] = useState([]);
  const [selectedEstampariaCor, setSelectedEstampariaCor] = useState(null);
  const [selectedEstampariaTamanho, setSelectedEstampariaTamanho] = useState(null);
  const [selectedEstampariaModelo, setSelectedEstampariaModelo] = useState(null);
  const [selectedEstampariaMalha, setSelectedEstampariaMalha] = useState(null);
  const [selectedEstampariaManga, setSelectedEstampariaManga] = useState(null);
  const [selectedEstampariaMangaAcabamento, setSelectedEstampariaMangaAcabamento] = useState(null);
  const [selectedEstampariaGola, setSelectedEstampariaGola] = useState(null);
  const [selectedEstampariaGolaAcabamento, setSelectedEstampariaGolaAcabamento] = useState(null);
  const [selectedEstampariaBolso, setSelectedEstampariaBolso] = useState(null);
  const [selectedEstampariaSilk, setSelectedEstampariaSilk] = useState(null);
  const [selectedEstampariaSublimacao, setSelectedEstampariaSublimacao] = useState(null);
  const [selectedEstampariaBordado, setSelectedEstampariaBordado] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [listaOrigemMercadoria] = useState([
    { label: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8', value: 0 },
    { label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6', value: 1 },
    { label: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7', value: 2 },
    { label: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% (quarenta por cento) e inferior ou igual a 70% (setenta por cento)', value: 3 },
    { label: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei no 288/67, e as Leis nos 8.248/91, 8.387/91, 10.176/01 e 11.484/07', value: 4 },
    { label: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40% (quarenta por cento)', value: 5 },
    { label: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução CAMEX e gás natural', value: 6 },
    { label: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX e gás natural', value: 7 },
    { label: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70% (setenta por cento)', value: 8 },
  ]);
  const [listaIcmsModalidadeBc] = useState([
    { label: '0 = Margem Valor Agregado (%)', value: 0 },
    { label: '1 = Pauta (Valor)', value: 1 },
    { label: '2 = Preço Tabelado Máx. (valor)', value: 2 },
    { label: '3 = Valor da operação', value: 3 },
  ]);
  const [listaIcmsModalidadeBcSt] = useState([
    { label: '0 = Preço tabelado ou máximo sugerido', value: 0 },
    { label: '1 = Lista Negativa (valor)', value: 1 },
    { label: '2 = Lista Positiva (valor)', value: 2 },
    { label: '3 = Lista Neutra (valor)', value: 3 },
    { label: '4 = Margem Valor Agregado (%)', value: 4 },
    { label: '5 = Pauta (valor)', value: 5 },
  ]);
  const [listaIndProp] = useState([
    { label: '0- Item de propriedade do informante e em seu poder', value: 0 },
    { label: '1- Item de propriedade do informante em posse de terceiros', value: 1 },
    { label: '2- Item de propriedade de terceiros em posse do informante', value: 2 },
  ]);
  const [columnsSetup] = useState([
    { field: 'unidadeMedida', header: 'Unid', sort: true },
    { field: 'precoVenda', header: 'Preço Venda', sort: false },
    { field: 'precoVenda2', header: 'Preço Venda 2', sort: false },
    { field: 'precoVenda3', header: 'Preço Venda 3', sort: false },
    { field: 'precoVenda4', header: 'Preço Venda 4', sort: false },
    { field: 'precoVenda5', header: 'Preço Venda 5', sort: false },
  ]);
  const [columnsSetupEan] = useState([
    { field: 'codigoEan', header: 'Código EAN', sort: true },
    { field: 'ativo', header: 'Ativo', sort: false },
  ]);
  const [columnsSetupIngrediente] = useState([
    { field: 'descricaoProduto', header: 'Produto', sort: true },
    { field: 'quantidade', header: 'Quantidade', sort: false },
  ]);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    let newTitle = 'Produto - Cadastro';
    if (id) {
      newTitle = 'Produto - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getConfiguracaoSistema() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const params = `/configuracaosistema?idUsuario=${idUsuario}`;

        const response = await axiosAdmin.get(params);

        setDesabilitarCadastroProduto(get(response.data, 'desabilitarCadastroProduto', false));
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEmpresa(_cnpj) {
      try {
        setIsLoading(true);

        const response = await axiosAdmin.get(`/empresa/cnpj?cnpjEmpresa=${_cnpj}`);

        setEmpresaAliquotaPis(get(response.data, 'aliquotaPis', 0));
        setEmpresaAliquotaCofins(get(response.data, 'aliquotaCofins', 0));
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getUnidadeMedida() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _unidadesMedida } = await axiosAdmin.get(`/unidademedida?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresaProps}`);

        _unidadesMedida.forEach(element => {
          listDropdown.push({ label: `${element.sigla} ${element.descricao}`, sigla: element.sigla, value: element.id });
        });
        setListaUnidadeMedida(listDropdown);

        const _listaUnidadeMedidaVenda = [...listDropdown];

        _listaUnidadeMedidaVenda.unshift({ label: '', value: null });
        setListaUnidadeMedidaVenda(_listaUnidadeMedidaVenda);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getContaAnalitica() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _contasanaliticas } = await axiosAdmin.get(`/contaanalitica?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresaProps}`);

        _contasanaliticas.forEach(element => {
          listDropdown.push({ label: `${element.codigo} ${element.descricao}`, value: element.codigo });
        });

        setListaContaAnalitica(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getIpi() {
      try {
        setIsLoading(true);

        const listDropdownEntrada = [];
        const listDropdownSaida = [];
        const { data: _ipi } = await axiosAdmin.get('/ipi');

        _ipi.forEach(element => {
          if (element.codigo <= '49') {
            listDropdownEntrada.push({ label: `${element.codigo} ${element.descricao}`, value: element.codigo });
          } else {
            listDropdownSaida.push({ label: `${element.codigo} ${element.descricao}`, value: element.codigo });
          }
        });

        setListaIpiEntrada(listDropdownEntrada);
        setListaIpiSaida(listDropdownSaida);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getPisCofins() {
      try {
        setIsLoading(true);

        const listDropdownEntrada = [];
        const listDropdownSaida = [];
        const { data: _piscofins } = await axiosAdmin.get('/piscofins');

        _piscofins.forEach(element => {
          if (element.codigo <= '49' || element.codigo === '99') {
            listDropdownSaida.push({ label: `${element.codigo} ${element.descricao}`, value: element.codigo });
          }
          if (element.codigo > '49' || element.codigo === '99') {
            listDropdownEntrada.push({ label: `${element.codigo} ${element.descricao}`, value: element.codigo });
          }
        });

        setListaPisCofinsEntrada(listDropdownEntrada);
        setListaPisCofinsSaida(listDropdownSaida);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getGeneroProduto() {
      try {
        setIsLoading(true);

        const listDropdown = [];
        const { data: _unidadesMedida } = await axiosAdmin.get('/generoproduto');

        _unidadesMedida.forEach(element => {
          listDropdown.push({ label: `${element.codigo} ${element.descricao}`, value: element.codigo });
        });
        setListaGeneroProduto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getTipoProduto() {
      try {
        setIsLoading(true);

        const listDropdown = [];
        const { data: _unidadesMedida } = await axiosAdmin.get('/tipoproduto');

        _unidadesMedida.forEach(element => {
          listDropdown.push({ label: `${element.codigo} ${element.descricao}`, value: element.codigo });
        });
        setListaTipoProduto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getConfiguracaoSistema();
    getUnidadeMedida();
    getContaAnalitica();
    getIpi();
    getPisCofins();
    getGeneroProduto();
    getTipoProduto();

    const modeloNegocio = get(store.getState().auth.user, 'modeloNegocio', 'ESTAMPARIA');

    async function getEstampariaCor() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaCor } = await axiosAdmin.get(`/estampariacor?idUsuario=${idUsuario}`);

        _estampariaCor.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaCor(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaTamanho() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaTamanho } = await axiosAdmin.get(`/estampariatamanho?idUsuario=${idUsuario}`);

        _estampariaTamanho.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaTamanho(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaModelo() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaModelo } = await axiosAdmin.get(`/estampariamodelo?idUsuario=${idUsuario}`);

        _estampariaModelo.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaModelo(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaMalha() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaMalha } = await axiosAdmin.get(`/estampariamalha?idUsuario=${idUsuario}`);

        _estampariaMalha.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaMalha(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaManga() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaManga } = await axiosAdmin.get(`/estampariamanga?idUsuario=${idUsuario}`);

        _estampariaManga.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaManga(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaMangaAcabamento() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaMangaAcabamento } = await axiosAdmin.get(`/estampariamangaacabamento?idUsuario=${idUsuario}`);

        _estampariaMangaAcabamento.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaMangaAcabamento(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaGola() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaGola } = await axiosAdmin.get(`/estampariagola?idUsuario=${idUsuario}`);

        _estampariaGola.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaGola(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaGolaAcabamento() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaGolaAcabamento } = await axiosAdmin.get(`/estampariagolaacabamento?idUsuario=${idUsuario}`);

        _estampariaGolaAcabamento.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaGolaAcabamento(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaBolso() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaBolso } = await axiosAdmin.get(`/estampariabolso?idUsuario=${idUsuario}`);

        _estampariaBolso.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaBolso(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaSilk() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaSilk } = await axiosAdmin.get(`/estampariasilk?idUsuario=${idUsuario}`);

        _estampariaSilk.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaSilk(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaSublimacao() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaSublimacao } = await axiosAdmin.get(`/estampariasublimacao?idUsuario=${idUsuario}`);

        _estampariaSublimacao.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaSublimacao(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaBordado() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _estampariaBordado } = await axiosAdmin.get(`/estampariabordado?idUsuario=${idUsuario}`);

        _estampariaBordado.forEach(element => {
          listDropdown.push({ label: element.descricao, key: element.id });
        });

        setNodeEstampariaBordado(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    if (modeloNegocio === 'ESTAMPARIA') {
      getEstampariaCor();
      getEstampariaTamanho();
      getEstampariaModelo();
      getEstampariaMalha();
      getEstampariaManga();
      getEstampariaMangaAcabamento();
      getEstampariaGola();
      getEstampariaGolaAcabamento();
      getEstampariaBolso();
      getEstampariaSilk();
      getEstampariaSublimacao();
      getEstampariaBordado();
    }

    if (id <= 0) {
      setCnpjEmpresa(cnpjEmpresaProps);
      return;
    }

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/produto/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultaproduto');
          toast.error('Acesso negado');
        }

        const _dataCadastro = converteData(data.dataCadastro);

        const _dataInicioPromocao = converteData(data.dataInicioPromocao);

        const _dataFimPromocao = converteData(data.dataFimPromocao);

        getEmpresa(data.cnpjEmpresa.trim());

        setCnpjEmpresa(data.cnpjEmpresa.trim());
        setRegistro(data.registro);
        setDescricao(data.descricao);
        setDescricaoResumida(data.descricaoResumida);
        setCodigoEan(data.codigoEan);
        setReferencia(data.referencia);
        setCodigoSimilar(data.codigoSimilar);
        setMensagemNfe(data.mensagemNfe);
        setUnidadeMedida(get(data, 'unidadeMedida.id', 0));
        setUnidadeMedidaVenda(get(data, 'unidadeMedidaVenda.id', 0));
        setDescricaoGrupo(get(data.grupo, 'descricao', ''));
        setIdGrupo(get(data.grupo, 'id', null));
        setCodigoGrupo(get(data.grupo, 'codigo', ''));
        setDescricaoSubGrupo(get(data.subGrupo, 'descricao', ''));
        setIdSubGrupo(get(data.subGrupo, 'id', null));
        setCodigoSubGrupo(get(data.subGrupo, 'codigo', ''));
        setCfopVenda(data.cfopVenda);
        setCstVenda(data.cstVenda);
        setCsosnVenda(data.csosnVenda);
        setOrigemMercadoria(data.origemMercadoria);
        setIcmsAliquota(data.icmsAliquota);
        setIcmsAliquotaFcp(data.icmsAliquotaFcp);
        setIcmsAliquotaSt(data.icmsAliquotaSt);
        setIcmsPRedBc(data.icmsPRedBc);
        setIcmsPRedBcSt(data.icmsPRedBcSt);
        setIcmsModalidadeBc(data.icmsModalidadeBc);
        setIcmsModalidadeBcSt(data.icmsModalidadeBcSt);
        setPrecoCusto(data.precoCusto);
        setPrecoCustoReal(data.precoCustoReal);
        setPrecoVenda(data.precoVenda);
        setPrecoVenda2(data.precoVenda2);
        setPrecoVenda3(data.precoVenda3);
        setPrecoVenda4(data.precoVenda4);
        setPrecoVenda5(data.precoVenda5);
        setPrecoPromocao(data.precoPromocao);
        setDataInicioPromocao(_dataInicioPromocao);
        setDataFimPromocao(_dataFimPromocao);
        setQtdeAtacado2(data.qtdeAtacado2);
        setQtdeAtacado3(data.qtdeAtacado3);
        setQtdeAtacado4(data.qtdeAtacado4);
        setQtdeAtacado5(data.qtdeAtacado5);
        setLocalizacao(data.localizacao);
        setLocalizacao2(data.localizacao2);
        setLocalizacao3(data.localizacao3);
        setLocalizacao4(data.localizacao4);
        setLocalizacao5(data.localizacao5);
        setAceitarDesconto(get(data, 'aceitarDesconto', false));
        setPercentualDescontoMaximo(data.percentualDescontoMaximo);
        setPercentualComissao(data.percentualComissao);
        setPercentualComissaoMecanico(data.percentualComissaoMecanico);
        setMarca(data.marca);
        setTamanho(data.tamanho);
        setCor(data.cor);
        setComprimento(data.comprimento);
        setLargura(data.largura);
        setAltura(data.altura);
        setPesoBruto(data.pesoBruto);
        setPesoLiquido(data.pesoLiquido);
        setDataCadastro(_dataCadastro);
        setAtivo(get(data, 'ativo', false));
        setAplicacao(data.aplicacao);
        setCodigoFabrica(data.codigoFabrica);
        setNcm(data.ncm);
        setCest(data.cest);
        setGenero(data.genero);
        setCodLst(data.codLst);
        setMva(data.mva);
        setMvaSt(data.mvaSt);
        setQtdePorCx(data.qtdePorCx);
        setIpiEntradaCst(data.ipiEntradaCst);
        setIpiEntradaAliquota(data.ipiEntradaAliquota);
        setIpiSaidaCst(data.ipiSaidaCst);
        setIpiSaidaAliquota(data.ipiSaidaAliquota);
        setTipo(data.tipo);
        setExtIpi(data.extIpi);
        setTeclaBalanca(data.teclaBalanca);
        setCtaEntrada(data.ctaEntrada);
        setCtaSaida(data.ctaSaida);
        setCtaInventario(data.ctaInventario);
        setCtaNr(data.ctaNr);
        setDescricaoComplementarNr(data.descricaoComplementarNr);
        setPisCofinsEntradaCst(data.pisCofinsEntradaCst);
        setPisEntradaAliquota(data.pisEntradaAliquota);
        setCofinsEntradaAliquota(data.cofinsEntradaAliquota);
        setPisCofinsSaidaCst(data.pisCofinsSaidaCst);
        setPisSaidaAliquota(data.pisSaidaAliquota);
        setCofinsSaidaAliquota(data.cofinsSaidaAliquota);
        setNaturezaReceita(data.naturezaReceita);
        setIndProp(data.indProp);
        setQtdeIdealDeposito(data.qtdeIdealDeposito);
        setQtdeIdealPrateleira(data.qtdeIdealPrateleira);
        setQtdeMaximaDeposito(data.qtdeMaximaDeposito);
        setControlarEstoque(data.controlarEstoque);
        setEstampariaProducao(data.estampariaProducao);
        setEstampariaManga(data.estampariaManga);
        setEstampariaMangaAcabamento(data.estampariaMangaAcabamento);
        setEstampariaGola(data.estampariaGola);
        setEstampariaGolaAcabamento(data.estampariaGolaAcabamento);
        setEstampariaBolso(data.estampariaBolso);
        setFornecedorPrincipal(data.fornecedorPrincipal);

        const _listaPrecoUnidade = [];
        data.listaPrecoUnidade.forEach(element => {
          _listaPrecoUnidade.push({
            unidadeMedida: element.unidadeMedida.sigla,
            qtdeProdutoPai: element.qtdeProdutoPai,
            fatorConversao: element.fatorConversao,
            qtdeAcumulado: element.qtdeAcumulado,
            precoVenda: element.precoVenda,
            precoVenda2: element.precoVenda2,
            precoVenda3: element.precoVenda3,
            precoVenda4: element.precoVenda4,
            precoVenda5: element.precoVenda5,
          });
        });
        setListaPrecoUnidade(_listaPrecoUnidade);

        setListaEan(data.listaEan);
        setListaIngrediente(data.listaIngrediente);

        let dadosSetKey = {};
        data.listaEstampariaCor.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaCor.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaCor(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaTamanho.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaTamanho.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaTamanho(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaModelo.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaModelo.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaModelo(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaMalha.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaMalha.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaMalha(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaManga.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaManga.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaManga(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaMangaAcabamento.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaMangaAcabamento.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaMangaAcabamento(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaGola.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaGola.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaGola(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaGolaAcabamento.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaGolaAcabamento.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaGolaAcabamento(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaBolso.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaBolso.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaBolso(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaSilk.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaSilk.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaSilk(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaSublimacao.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaSublimacao.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaSublimacao(dadosSetKey);

        dadosSetKey = {};
        data.listaEstampariaBordado.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.estampariaBordado.id]: { checked: true, partialChecked: true } };
        });
        setSelectedEstampariaBordado(dadosSetKey);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let _dataInicioPromocao = null;
      if (dataInicioPromocao) {
        _dataInicioPromocao = formataDataMoment(dataInicioPromocao, '-');
      }
      let _dataFimPromocao = null;
      if (dataFimPromocao) {
        _dataFimPromocao = formataDataMoment(dataFimPromocao, '-');
      }

      const listaEstampariaCor = [];
      if (selectedEstampariaCor !== null) {
        Object.keys(selectedEstampariaCor).forEach((item) => {
          listaEstampariaCor.push(item);
        });
      }
      const listaEstampariaTamanho = [];
      if (selectedEstampariaTamanho !== null) {
        Object.keys(selectedEstampariaTamanho).forEach((item) => {
          listaEstampariaTamanho.push(item);
        });
      }
      const listaEstampariaModelo = [];
      if (selectedEstampariaModelo !== null) {
        Object.keys(selectedEstampariaModelo).forEach((item) => {
          listaEstampariaModelo.push(item);
        });
      }
      const listaEstampariaMalha = [];
      if (selectedEstampariaMalha !== null) {
        Object.keys(selectedEstampariaMalha).forEach((item) => {
          listaEstampariaMalha.push(item);
        });
      }
      const listaEstampariaManga = [];
      if (selectedEstampariaManga !== null) {
        Object.keys(selectedEstampariaManga).forEach((item) => {
          listaEstampariaManga.push(item);
        });
      }
      const listaEstampariaMangaAcabamento = [];
      if (selectedEstampariaMangaAcabamento !== null) {
        Object.keys(selectedEstampariaMangaAcabamento).forEach((item) => {
          listaEstampariaMangaAcabamento.push(item);
        });
      }
      const listaEstampariaGola = [];
      if (selectedEstampariaGola !== null) {
        Object.keys(selectedEstampariaGola).forEach((item) => {
          listaEstampariaGola.push(item);
        });
      }
      const listaEstampariaGolaAcabamento = [];
      if (selectedEstampariaGolaAcabamento !== null) {
        Object.keys(selectedEstampariaGolaAcabamento).forEach((item) => {
          listaEstampariaGolaAcabamento.push(item);
        });
      }
      const listaEstampariaBolso = [];
      if (selectedEstampariaBolso !== null) {
        Object.keys(selectedEstampariaBolso).forEach((item) => {
          listaEstampariaBolso.push(item);
        });
      }
      const listaEstampariaSilk = [];
      if (selectedEstampariaSilk !== null) {
        Object.keys(selectedEstampariaSilk).forEach((item) => {
          listaEstampariaSilk.push(item);
        });
      }
      const listaEstampariaSublimacao = [];
      if (selectedEstampariaSublimacao !== null) {
        Object.keys(selectedEstampariaSublimacao).forEach((item) => {
          listaEstampariaSublimacao.push(item);
        });
      }
      const listaEstampariaBordado = [];
      if (selectedEstampariaBordado !== null) {
        Object.keys(selectedEstampariaBordado).forEach((item) => {
          listaEstampariaBordado.push(item);
        });
      }

      const shippingData = {
        idUsuario,
        cnpjEmpresa,
        registro,
        descricao,
        descricaoResumida,
        codigoEan,
        referencia,
        codigoSimilar,
        mensagemNfe,
        idUnidadeMedida: unidadeMedida,
        idUnidadeMedidaVenda: unidadeMedidaVenda,
        grupoId: idGrupo,
        subGrupoId: idSubGrupo,
        cfopVenda,
        cstVenda,
        csosnVenda,
        origemMercadoria,
        icmsAliquota,
        icmsAliquotaFcp,
        icmsAliquotaSt,
        icmsPRedBc,
        icmsPRedBcSt,
        icmsModalidadeBc,
        icmsModalidadeBcSt,
        precoCusto,
        precoCustoReal,
        precoVenda,
        precoVenda2,
        precoVenda3,
        precoVenda4,
        precoVenda5,
        precoPromocao,
        dataInicioPromocao: _dataInicioPromocao,
        dataFimPromocao: _dataFimPromocao,
        qtdeAtacado2,
        qtdeAtacado3,
        qtdeAtacado4,
        qtdeAtacado5,
        localizacao,
        localizacao2,
        localizacao3,
        localizacao4,
        localizacao5,
        aceitarDesconto,
        percentualDescontoMaximo,
        percentualComissao,
        percentualComissaoMecanico,
        marca,
        tamanho,
        cor,
        comprimento,
        largura,
        altura,
        pesoBruto,
        pesoLiquido,
        ativo,
        aplicacao,
        codigoFabrica,
        ncm,
        cest,
        genero,
        codLst,
        mva,
        mvaSt,
        qtdePorCx,
        ipiEntradaCst,
        ipiEntradaAliquota,
        ipiSaidaCst,
        ipiSaidaAliquota,
        tipo,
        extIpi,
        teclaBalanca,
        ctaEntrada,
        ctaSaida,
        ctaInventario,
        ctaNr,
        descricaoComplementarNr,
        pisCofinsEntradaCst,
        pisEntradaAliquota,
        cofinsEntradaAliquota,
        pisCofinsSaidaCst,
        pisSaidaAliquota,
        cofinsSaidaAliquota,
        naturezaReceita,
        indProp,
        qtdeIdealDeposito,
        qtdeIdealPrateleira,
        qtdeMaximaDeposito,
        controlarEstoque,
        estampariaProducao,
        estampariaManga,
        estampariaMangaAcabamento,
        estampariaGola,
        estampariaGolaAcabamento,
        estampariaBolso,
        fornecedorPrincipal,
        listaPrecoUnidade,
        listaEan,
        listaIngrediente,
        listaEstampariaCor,
        listaEstampariaTamanho,
        listaEstampariaModelo,
        listaEstampariaMalha,
        listaEstampariaManga,
        listaEstampariaMangaAcabamento,
        listaEstampariaGola,
        listaEstampariaGolaAcabamento,
        listaEstampariaBolso,
        listaEstampariaSilk,
        listaEstampariaSublimacao,
        listaEstampariaBordado,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/produto/${id}`, shippingData), 'data', {});
        toast.success('Produto atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/produto', shippingData), 'data', {});
        toast.success('Produto cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (descricao.length < 3 || descricao.length > 120) {
      formErros = true;
      toast.error('Descrição deve ter entre 3 e 120 caracteres');
    }
    // if (codigoEan && !validate(codigoEan)) {
    //   formErros = true;
    //   toast.error('Código EAN inválido.');
    // }
    if (unidadeMedida <= 0) {
      formErros = true;
      toast.error('Unidade de medida deve ser informada.');
    }
    if (idGrupo <= 0) {
      formErros = true;
      toast.error('Grupo deve ser informado.');
    }
    if (precoVenda <= 0) {
      formErros = true;
      toast.error('Preço de Venda deve ser informado.');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const handleIncluirPrecoUnidade = () => {
    let incluirPrecoUnidade = true;

    if (pprUnidadeMedida <= 0) {
      toast.warning('Unidade de medida deve ser informada');
      return;
    }
    if (pprPrecoVenda <= 0) {
      toast.warning('Preço de venda deve ser informado');
      return;
    }
    if (pprPrecoVenda2 < 0) {
      toast.warning('Preço de venda 2 não pode ser negativo');
      return;
    }
    if (pprPrecoVenda3 < 0) {
      toast.warning('Preço de venda 3 não pode ser negativo');
      return;
    }
    if (pprPrecoVenda4 < 0) {
      toast.warning('Preço de venda 4 não pode ser negativo');
      return;
    }
    if (pprPrecoVenda5 < 0) {
      toast.warning('Preço de venda 5 não pode ser negativo');
      return;
    }
    if (pprFatorConversao < 0) {
      toast.warning('Fator de conversão não pode ser negativo');
      return;
    }

    let siglaUnidadeMedida = '';

    listaUnidadeMedida.forEach(elementListaUnidadeMedida => {
      if (elementListaUnidadeMedida.value === pprUnidadeMedida) {
        siglaUnidadeMedida = elementListaUnidadeMedida.sigla;
      }
    });

    listaPrecoUnidade.forEach(elementListaPrecoUnidade => {
      if (elementListaPrecoUnidade.unidadeMedida === siglaUnidadeMedida && incluirPrecoUnidade === true) {
        toast.warning('Unidade de medida já existe, inclusão não permitida');
        incluirPrecoUnidade = false;
      }
    });

    if (incluirPrecoUnidade === false) return;

    listaPrecoUnidade.push({
      unidadeMedida: siglaUnidadeMedida,
      qtdeProdutoPai: 0,
      fatorConversao: pprFatorConversao,
      qtdeAcumulado: 0,
      precoVenda: pprPrecoVenda,
      precoVenda2: pprPrecoVenda2,
      precoVenda3: pprPrecoVenda3,
      precoVenda4: pprPrecoVenda4,
      precoVenda5: pprPrecoVenda5,
    });

    setPprUnidadeMedida(null);
    setPprFatorConversao(0);
    setPprPrecoVenda(0);
    setPprPrecoVenda2(0);
    setPprPrecoVenda3(0);
    setPprPrecoVenda4(0);
    setPprPrecoVenda5(0);

    setShowCadastroPrecoUnidade(false);
  };

  const handleExcluirPrecoUnidade = () => {
    const unidadeMedidaExclusao = get(selectedPrecoUnidade, 'unidadeMedida', '');

    if (unidadeMedidaExclusao === null || unidadeMedidaExclusao === '') {
      toast.warning('Por favor, selecione o registro para exclusão');
    }

    const _listaPrecoUnidade = listaPrecoUnidade.filter(val => val.unidadeMedida !== unidadeMedidaExclusao);
    setListaPrecoUnidade(_listaPrecoUnidade);

    setSelectedPrecoUnidade(null);
  };

  const onHideCadastroPrecoUnidade = () => {
    setShowCadastroPrecoUnidade(false);
  };

  const handleIncluirEan = () => {
    let incluirEan = true;

    if (!peaCodigoEan) {
      toast.warning('Código EAN deve ser informado');
      return;
    }

    listaEan.forEach(elementListaEan => {
      if (elementListaEan.codigoEan === peaCodigoEan && incluirEan === true) {
        toast.warning('Código EAN já existe, inclusão não permitida');
        incluirEan = false;
      }
    });

    if (incluirEan === false) return;

    listaEan.push({
      codigoEan: peaCodigoEan,
      ativo: peaAtivo,
    });

    setPeaCodigoEan('');
    setPeaAtivo(true);

    setShowCadastroEan(false);
  };

  const handleExcluirEan = () => {
    const idExclusao = get(selectedEan, 'codigoEan', '');

    if (idExclusao === null || idExclusao === '') {
      toast.warning('Por favor, selecione o registro para exclusão');
    }

    const _listaEan = listaEan.filter(val => val.codigoEan !== idExclusao);
    setListaEan(_listaEan);

    setSelectedEan(null);
  };

  const onHideCadastroEan = () => {
    setShowCadastroEan(false);
  };

  const handleIncluirIngrediente = () => {
    let incluirIngrediente = true;

    if (pinIdProdutoIngrediente <= 0) {
      toast.warning('Produto deve ser informado');
      return;
    }
    if (pinQuantidade <= 0) {
      toast.warning('Quantidade deve ser informada');
      return;
    }

    listaIngrediente.forEach(elementListaIngrediente => {
      if (elementListaIngrediente.idProdutoIngrediente === pinIdProdutoIngrediente && incluirIngrediente === true) {
        toast.warning('Produto já existe como ingrediente, inclusão não permitida');
        incluirIngrediente = false;
      }
    });

    if (incluirIngrediente === false) return;

    listaIngrediente.push({
      idProdutoIngrediente: pinIdProdutoIngrediente,
      descricaoProduto: pinDescricaoProdutoIngrediente,
      quantidade: pinQuantidade,
    });

    setPinIdProdutoIngrediente(0);
    setPinDescricaoProdutoIngrediente('');
    setPinQuantidade(0);

    setShowCadastroIngrediente(false);
  };

  const handleExcluirIngrediente = () => {
    const idExclusao = get(selectedIngrediente, 'idProdutoIngrediente', 0);

    if (idExclusao <= 0) {
      toast.warning('Por favor, selecione o registro para exclusão');
    }

    const _listaIngrediente = listaIngrediente.filter(val => val.idProdutoIngrediente !== idExclusao);
    setListaIngrediente(_listaIngrediente);

    setSelectedIngrediente(null);
  };

  const onHideCadastroIngrediente = () => {
    setShowCadastroIngrediente(false);
  };

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  const onHideConsultaGrupo = () => {
    setShowConsultaGrupo(false);
  };

  const onHideConsultaSubGrupo = () => {
    setShowConsultaSubGrupo(false);
  };

  const handleFocus = (event) => event.target.select();

  const titulo = () => (id ? 'Produto - Alteração' : 'Produto - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                disabled={desabilitarCadastroProduto}
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const childToParentConsultaProduto = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setPinIdProdutoIngrediente(e.id);
        setPinDescricaoProdutoIngrediente(e.descricao);
      }
      setShowConsultaProduto(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  // eslint-disable-next-line consistent-return
  async function handleBuscarGrupoByCodigo(e) {
    try {
      setIsLoading(true);

      if (e.length <= 0) {
        setIdGrupo('');
        setDescricaoGrupo('');
        return false;
      }

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/grupo?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresaProps}`;

      params = `${params}&codigo=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data.length > 0) {
        setIdGrupo(response.data[0].id);
        setDescricaoGrupo(response.data[0].descricao);
      } else {
        setIdGrupo('');
        setDescricaoGrupo('');
        toast.info('Nenhum grupo com esse código encontrado');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  // eslint-disable-next-line consistent-return
  async function handleBuscarSubGrupoByCodigo(e) {
    try {
      setIsLoading(true);

      if (e.length <= 0) {
        setIdSubGrupo('');
        setDescricaoSubGrupo('');
        return false;
      }

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/subgrupo?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresaProps}`;

      params = `${params}&codigo=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data.length > 0) {
        setIdSubGrupo(response.data[0].id);
        setDescricaoSubGrupo(response.data[0].descricao);
      } else {
        setIdSubGrupo('');
        setDescricaoSubGrupo('');
        toast.info('Nenhum subgrupo com esse código encontrado');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const childToParentGrupo = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdGrupo(e.id);
      }
      if (e.codigo) {
        setCodigoGrupo(e.codigo);
      }
      if (e.descricao) {
        setDescricaoGrupo(e.descricao);
      }
      setShowConsultaGrupo(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentSubGrupo = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdSubGrupo(e.id);
      }
      if (e.codigo) {
        setCodigoSubGrupo(e.codigo);
      }
      if (e.descricao) {
        setDescricaoSubGrupo(e.descricao);
      }
      setShowConsultaSubGrupo(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Produtos"
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaProduto()}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} />
      </Dialog>

      <Dialog
        header="Consulta de Grupo"
        visible={showConsultaGrupo}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaGrupo()}
      >
        <ConsultaGrupo menuMode={false} childToParent={childToParentGrupo} />
      </Dialog>

      <Dialog
        header="Consulta de Sub-Grupo"
        visible={showConsultaSubGrupo}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaSubGrupo()}
      >
        <ConsultaSubGrupo menuMode={false} childToParent={childToParentSubGrupo} />
      </Dialog>

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <TabView activeIndex={activeTabIndex} scrollable onTabChange={(e) => setActiveTabIndex(e.index)}>
          <TabPanel header="Principal">
            <div className="row form-elo">
              <div className="col-11">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Descrição
                  </Input200px>
                  <InputText
                    maxLength={120}
                    value={descricao}
                    onChange={e => setDescricao(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-1" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={ativo}
                    onChange={e => setAtivo(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Ativo
                  </label>
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Descrição Resumida
                  </Input200px>
                  <InputText
                    maxLength={25}
                    value={descricaoResumida}
                    onChange={e => setDescricaoResumida(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Código EAN
                  </Input155px>
                  <InputText
                    maxLength={14}
                    value={codigoEan}
                    onChange={e => setCodigoEan(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Cód. Fabrica
                  </Input155px>
                  <InputText
                    maxLength={100}
                    value={codigoFabrica}
                    onChange={e => setCodigoFabrica(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Referência
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={referencia}
                    onChange={e => setReferencia(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Cód. Similar
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={codigoSimilar}
                    onChange={e => setCodigoSimilar(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Unidade Medida
                  </Input155px>
                  <Dropdown
                    options={listaUnidadeMedida}
                    value={unidadeMedida}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setUnidadeMedida(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Unidade Medida Venda
                  </Input200px>
                  <Dropdown
                    options={listaUnidadeMedidaVenda}
                    value={unidadeMedidaVenda}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setUnidadeMedidaVenda(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Cód. Grupo
                  </Input155px>
                  <InputText
                    maxLength={255}
                    value={codigoGrupo}
                    onChange={e => setCodigoGrupo(e.target.value)}
                    onBlur={e => handleBuscarGrupoByCodigo(e.target.value)}
                  />
                  <Button
                    icon="pi pi-search"
                    className="p-button-warning"
                    onClick={() => setShowConsultaGrupo(true)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <InputText
                    disabled
                    maxLength={255}
                    value={descricaoGrupo}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Cód. SubGrupo
                  </Input155px>
                  <InputText
                    maxLength={255}
                    value={codigoSubGrupo}
                    onChange={e => setCodigoSubGrupo(e.target.value)}
                    onBlur={e => handleBuscarSubGrupoByCodigo(e.target.value)}
                  />
                  <Button
                    icon="pi pi-search"
                    className="p-button-warning"
                    onClick={() => setShowConsultaSubGrupo(true)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <InputText
                    disabled
                    maxLength={255}
                    value={descricaoSubGrupo}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Preço de Custo
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={precoCusto}
                    onValueChange={e => setPrecoCusto(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Preço Custo Real
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={precoCustoReal}
                    onValueChange={e => setPrecoCustoReal(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Preço de Venda
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={precoVenda}
                    onValueChange={e => setPrecoVenda(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Preço Promoção
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={precoPromocao}
                    onValueChange={e => setPrecoPromocao(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Início Promoção
                  </Input155px>
                  <Calendar
                    id="mask"
                    showIcon
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showOnFocus={false}
                    locale="pt-BR"
                    value={dataInicioPromocao}
                    onChange={(e) => setDataInicioPromocao(e.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Fim Promoção
                  </Input155px>
                  <Calendar
                    id="mask"
                    showIcon
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showOnFocus={false}
                    locale="pt-BR"
                    value={dataFimPromocao}
                    onChange={(e) => setDataFimPromocao(e.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Cód.Tributos">
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    NCM
                  </Input155px>
                  <InputText
                    maxLength={8}
                    value={ncm}
                    onChange={e => setNcm(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CEST
                  </Input155px>
                  <InputText
                    maxLength={7}
                    value={cest}
                    onChange={e => setCest(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Ex. TIPI
                  </Input155px>
                  <InputText
                    maxLength={3}
                    value={extIpi}
                    onChange={e => setExtIpi(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Cód. LST
                  </Input155px>
                  <InputText
                    maxLength={5}
                    value={codLst}
                    onChange={e => setCodLst(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Tipo
                  </Input155px>
                  <Dropdown
                    options={listaTipoProduto}
                    value={tipo}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setTipo(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Genero
                  </Input155px>
                  <Dropdown
                    options={listaGeneroProduto}
                    value={genero}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setGenero(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CTA Entrada
                  </Input155px>
                  <Dropdown
                    options={listaContaAnalitica}
                    value={ctaEntrada}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setCtaEntrada(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CTA Saída
                  </Input155px>
                  <Dropdown
                    options={listaContaAnalitica}
                    value={ctaSaida}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setCtaSaida(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CTA Inventário
                  </Input155px>
                  <Dropdown
                    options={listaContaAnalitica}
                    value={ctaInventario}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setCtaInventario(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CTA NR
                  </Input155px>
                  <Dropdown
                    options={listaContaAnalitica}
                    value={ctaNr}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setCtaNr(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input400px className="p-inputgroup-addon">
                    Descrição Complementar NR
                  </Input400px>
                  <InputText
                    maxLength={100}
                    value={descricaoComplementarNr}
                    onChange={e => setDescricaoComplementarNr(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="ICMS">
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Origem Mercadoria
                  </Input200px>
                  <Dropdown
                    options={listaOrigemMercadoria}
                    value={origemMercadoria}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setOrigemMercadoria(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CST Venda
                  </Input155px>
                  <InputText
                    maxLength={2}
                    value={cstVenda}
                    onChange={e => setCstVenda(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CFOP Venda
                  </Input155px>
                  <InputText
                    maxLength={3}
                    value={cfopVenda}
                    onChange={e => setCfopVenda(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CSOSN Venda
                  </Input155px>
                  <InputText
                    maxLength={3}
                    value={csosnVenda}
                    onChange={e => setCsosnVenda(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Alíquota ICMS
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={icmsAliquota}
                    onValueChange={e => setIcmsAliquota(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Alíquota ICMS FCP
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={icmsAliquotaFcp}
                    onValueChange={e => setIcmsAliquotaFcp(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Alíquota ICMS ST
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={icmsAliquotaSt}
                    onValueChange={e => setIcmsAliquotaSt(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Percentual Redução BC ICMS
                  </Input200px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={icmsPRedBc}
                    onValueChange={e => setIcmsPRedBc(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Percentual Redução BC ICMS ST
                  </Input200px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={icmsPRedBcSt}
                    onValueChange={e => setIcmsPRedBcSt(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Modalidade BC ICMS
                  </Input200px>
                  <Dropdown
                    options={listaIcmsModalidadeBc}
                    value={icmsModalidadeBc}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setIcmsModalidadeBc(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Modalidade BC ICMS ST
                  </Input200px>
                  <Dropdown
                    options={listaIcmsModalidadeBcSt}
                    value={icmsModalidadeBcSt}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setIcmsModalidadeBcSt(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    MVA
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={mva}
                    onValueChange={e => setMva(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    MVA ST
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={mvaSt}
                    onValueChange={e => setMvaSt(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="IPI">
            <div className="row form-elo">
              <div className="col-lg-9">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CST Entrada
                  </Input155px>
                  <Dropdown
                    options={listaIpiEntrada}
                    value={ipiEntradaCst}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setIpiEntradaCst(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Alíquota
                  </Input100px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={ipiEntradaAliquota}
                    onValueChange={e => setIpiEntradaAliquota(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-lg-9">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CST Saída
                  </Input155px>
                  <Dropdown
                    options={listaIpiSaida}
                    value={ipiSaidaCst}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setIpiSaidaCst(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Alíquota
                  </Input100px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={ipiSaidaAliquota}
                    onValueChange={e => setIpiSaidaAliquota(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="PIS/COFINS">
            <div className="row form-elo">
              <div className="col-lg-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CST Entrada
                  </Input155px>
                  <Dropdown
                    options={listaPisCofinsEntrada}
                    value={pisCofinsEntradaCst}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => {
                      setPisCofinsEntradaCst(e.target.value);
                      if (e.target.value === '50') {
                        setPisEntradaAliquota(empresaAliquotaPis);
                        setCofinsEntradaAliquota(empresaAliquotaCofins);
                      } else {
                        setPisEntradaAliquota(0);
                        setCofinsEntradaAliquota(0);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    PIS Alíquota
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={pisEntradaAliquota}
                    onValueChange={e => setPisEntradaAliquota(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    COFINS Alíquota
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={cofinsEntradaAliquota}
                    onValueChange={e => setCofinsEntradaAliquota(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <br />
            <div className="row form-elo">
              <div className="col-lg-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CST Saída
                  </Input155px>
                  <Dropdown
                    options={listaPisCofinsSaida}
                    value={pisCofinsSaidaCst}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => {
                      setPisCofinsSaidaCst(e.target.value);
                      if (e.target.value === '01') {
                        setPisSaidaAliquota(empresaAliquotaPis);
                        setCofinsSaidaAliquota(empresaAliquotaCofins);
                      } else {
                        setPisSaidaAliquota(0);
                        setCofinsSaidaAliquota(0);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    PIS Alíquota
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={pisSaidaAliquota}
                    onValueChange={e => setPisSaidaAliquota(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    COFINS Alíquota
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={cofinsSaidaAliquota}
                    onValueChange={e => setCofinsSaidaAliquota(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <br />
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Natureza Receita
                  </Input155px>
                  <InputText
                    maxLength={3}
                    value={naturezaReceita}
                    onChange={e => setNaturezaReceita(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Especificações">
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Aplicação
                  </Input155px>
                  <InputText
                    maxLength={255}
                    value={aplicacao}
                    onChange={e => setAplicacao(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Marca
                  </Input155px>
                  <InputText
                    maxLength={40}
                    value={marca}
                    onChange={e => setMarca(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Tamanho
                  </Input155px>
                  <InputText
                    maxLength={20}
                    value={tamanho}
                    onChange={e => setTamanho(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Cor
                  </Input155px>
                  <InputText
                    maxLength={20}
                    value={cor}
                    onChange={e => setCor(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Comprimento
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={comprimento}
                    onValueChange={e => setComprimento(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Largura
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={largura}
                    onValueChange={e => setLargura(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Altura
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={altura}
                    onValueChange={e => setAltura(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Peso Bruto
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={pesoBruto}
                    onValueChange={e => setPesoBruto(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Peso Líquido
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={pesoLiquido}
                    onValueChange={e => setPesoLiquido(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Qtde por Cx
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={qtdePorCx}
                    onValueChange={e => setQtdePorCx(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Dados Adicionais">
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Localização
                  </Input155px>
                  <InputText
                    maxLength={60}
                    value={localizacao}
                    onChange={e => setLocalizacao(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Localização 2
                  </Input155px>
                  <InputText
                    maxLength={60}
                    value={localizacao2}
                    onChange={e => setLocalizacao2(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Localização 3
                  </Input155px>
                  <InputText
                    maxLength={60}
                    value={localizacao3}
                    onChange={e => setLocalizacao3(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Localização 4
                  </Input155px>
                  <InputText
                    maxLength={60}
                    value={localizacao4}
                    onChange={e => setLocalizacao4(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Localização 5
                  </Input155px>
                  <InputText
                    maxLength={60}
                    value={localizacao5}
                    onChange={e => setLocalizacao5(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Tecla Balança
                  </Input155px>
                  <InputNumber
                    min={0}
                    max={9999}
                    readOnly
                    value={teclaBalanca}
                    onValueChange={e => setTeclaBalanca(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-9">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Indicador Propriedade
                  </Input200px>
                  <Dropdown
                    options={listaIndProp}
                    value={indProp}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setIndProp(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Qtde Máxima Depósito
                  </Input200px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={qtdeIdealDeposito}
                    onValueChange={e => setQtdeIdealDeposito(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Qtde Ideal Depósito
                  </Input200px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={qtdeIdealDeposito}
                    onValueChange={e => setQtdeIdealDeposito(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Qtde Ideal Prateleira
                  </Input200px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={qtdeIdealPrateleira}
                    onValueChange={e => setQtdeIdealPrateleira(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Comissão
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={percentualComissao}
                    onValueChange={e => setPercentualComissao(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Comis. Mecânico
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={percentualComissaoMecanico}
                    onValueChange={e => setPercentualComissaoMecanico(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Desconto Máximo
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={percentualDescontoMaximo}
                    onValueChange={e => setPercentualDescontoMaximo(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={aceitarDesconto}
                    onChange={e => setAceitarDesconto(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Aceitar Desconto
                  </label>
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-10">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Mensagem para NFe
                  </Input200px>
                  <InputText
                    maxLength={60}
                    value={mensagemNfe}
                    onChange={e => setMensagemNfe(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={controlarEstoque}
                    onChange={e => setControlarEstoque(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Controlar Estoque
                  </label>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Preços Auxiliares">
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Preço Venda 2
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={precoVenda2}
                    onValueChange={e => setPrecoVenda2(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Qtde Atacado 2
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={qtdeAtacado2}
                    onValueChange={e => setQtdeAtacado2(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Preço Venda 3
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={precoVenda3}
                    onValueChange={e => setPrecoVenda3(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Qtde Atacado 3
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={qtdeAtacado3}
                    onValueChange={e => setQtdeAtacado3(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Preço Venda 4
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={precoVenda4}
                    onValueChange={e => setPrecoVenda4(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Qtde Atacado 4
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={qtdeAtacado4}
                    onValueChange={e => setQtdeAtacado4(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Preço Venda 5
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={precoVenda5}
                    onValueChange={e => setPrecoVenda5(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Qtde Atacado 5
                  </Input155px>
                  <InputNumber
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={qtdeAtacado5}
                    onValueChange={e => setQtdeAtacado5(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Preços por Unidade">
            <DataTable
              value={listaPrecoUnidade}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              selectionMode="single"
              selection={selectedPrecoUnidade}
              onSelectionChange={e => setSelectedPrecoUnidade(e.value)}
              selectableRows
              dataKey="unidadeMedida"
              emptyMessage="Nenhum preço por unidade cadastrado"
            >
              {fillColumns(columnsSetup)}
            </DataTable>
            <br />
            <Dialog
              header="Produtos - Preço por Unidade"
              visible={showCadastroPrecoUnidade}
              style={{ width: '98vw' }}
              onHide={() => onHideCadastroPrecoUnidade()}
            >
              <div className="row form-elo">
                <div className="col-lg-12">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Unidade Medida
                    </Input155px>
                    <Dropdown
                      autoFocus
                      options={listaUnidadeMedida}
                      value={pprUnidadeMedida}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setPprUnidadeMedida(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Preço Venda
                    </Input155px>
                    <InputNumber
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={pprPrecoVenda}
                      onValueChange={e => setPprPrecoVenda(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Preço Venda 2
                    </Input155px>
                    <InputNumber
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={pprPrecoVenda2}
                      onValueChange={e => setPprPrecoVenda2(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Preço Venda 3
                    </Input155px>
                    <InputNumber
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={pprPrecoVenda3}
                      onValueChange={e => setPprPrecoVenda3(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Preço Venda 4
                    </Input155px>
                    <InputNumber
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={pprPrecoVenda4}
                      onValueChange={e => setPprPrecoVenda4(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Preço Venda 5
                    </Input155px>
                    <InputNumber
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={pprPrecoVenda5}
                      onValueChange={e => setPprPrecoVenda5(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Fator Conversão
                    </Input155px>
                    <InputNumber
                      mode="decimal"
                      inputId="minmaxfraction"
                      minFractionDigits={3}
                      maxFractionDigits={3}
                      value={pprFatorConversao}
                      onValueChange={e => setPprFatorConversao(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleIncluirPrecoUnidade}
                  >
                    <i className="pi pi-check"></i>
                    Ok
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setShowCadastroPrecoUnidade(false)}
                  >
                    <i className="pi pi-times"></i>
                    Cancelar
                  </button>
                </div>
              </div>
            </Dialog>
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  type="button"
                  title="Cadastrar"
                  className="btn btn-primary"
                  style={{ borderRadius: '15px' }}
                  onClick={() => setShowCadastroPrecoUnidade(true)}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  type="button"
                  title="Excluir"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleExcluirPrecoUnidade(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Código EAN Adicional">
            <DataTable
              value={listaEan}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              selectionMode="single"
              selection={selectedEan}
              onSelectionChange={e => setSelectedEan(e.value)}
              selectableRows
              dataKey="codigoEan"
              emptyMessage="Nenhum Código EAN Adicional cadastrado"
            >
              {fillColumns(columnsSetupEan)}
            </DataTable>
            <br />
            <Dialog
              header="Produtos - EAN Adicional"
              visible={showCadastroEan}
              style={{ width: '98vw' }}
              onHide={() => onHideCadastroEan()}
            >
              <div className="row form-elo">
                <div className="col-lg-10">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Código EAN
                    </Input155px>
                    <InputText
                      autoFocus
                      maxLength={14}
                      value={peaCodigoEan}
                      onChange={e => setPeaCodigoEan(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-1" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <div className="field-checkbox">
                    <Checkbox
                      inputId="binary"
                      checked={peaAtivo}
                      onChange={e => setPeaAtivo(e.checked)}
                    />
                    <label htmlFor="binary">
                      &nbsp;Ativo
                    </label>
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleIncluirEan}
                  >
                    <i className="pi pi-check"></i>
                    Ok
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setShowCadastroEan(false)}
                  >
                    <i className="pi pi-times"></i>
                    Cancelar
                  </button>
                </div>
              </div>
            </Dialog>
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  type="button"
                  title="Cadastrar"
                  className="btn btn-primary"
                  style={{ borderRadius: '15px' }}
                  onClick={() => setShowCadastroEan(true)}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  type="button"
                  title="Excluir"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleExcluirEan(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Ingredientes">
            <DataTable
              value={listaIngrediente}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              selectionMode="single"
              selection={selectedIngrediente}
              onSelectionChange={e => setSelectedIngrediente(e.value)}
              selectableRows
              dataKey="idProdutoIngrediente"
              emptyMessage="Nenhum Ingrediente cadastrado"
            >
              {fillColumns(columnsSetupIngrediente)}
            </DataTable>
            <br />
            <Dialog
              header="Produtos -Ingrediente"
              visible={showCadastroIngrediente}
              style={{ width: '98vw' }}
              onHide={() => onHideCadastroIngrediente()}
            >
              <div className="row form-elo">
                <div className="col-8">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Produto
                    </Input155px>
                    <InputText
                      disabled
                      value={pinDescricaoProdutoIngrediente}
                    />
                    <Button
                      icon="pi pi-search"
                      className="p-button-warning"
                      onClick={() => setShowConsultaProduto(true)}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Quantidade
                    </Input155px>
                    <InputNumber
                      mode="decimal"
                      inputId="minmaxfraction"
                      minFractionDigits={3}
                      maxFractionDigits={3}
                      value={pinQuantidade}
                      onValueChange={e => setPinQuantidade(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleIncluirIngrediente}
                  >
                    <i className="pi pi-check"></i>
                    Ok
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setShowCadastroIngrediente(false)}
                  >
                    <i className="pi pi-times"></i>
                    Cancelar
                  </button>
                </div>
              </div>
            </Dialog>
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  type="button"
                  title="Cadastrar"
                  className="btn btn-primary"
                  style={{ borderRadius: '15px' }}
                  onClick={() => setShowCadastroIngrediente(true)}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  type="button"
                  title="Excluir"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleExcluirIngrediente(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Estamparia">
            <div className="row form-elo">
              <div className="col-lg-3" style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={estampariaManga}
                    onChange={e => setEstampariaManga(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Pedir Manga
                  </label>
                </div>
              </div>
              <div className="col-lg-3" style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={estampariaMangaAcabamento}
                    onChange={e => setEstampariaMangaAcabamento(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Pedir Acabamento da Manga
                  </label>
                </div>
              </div>
              <div className="col-lg-3" style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={estampariaGola}
                    onChange={e => setEstampariaGola(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Pedir Gola
                  </label>
                </div>
              </div>
              <div className="col-lg-3" style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={estampariaGolaAcabamento}
                    onChange={e => setEstampariaGolaAcabamento(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Pedir Acabamento da Gola
                  </label>
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-lg-3" style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={estampariaBolso}
                    onChange={e => setEstampariaBolso(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Pedir Bolso
                  </label>
                </div>
              </div>
              <div className="col-lg-3" style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={estampariaProducao}
                    onChange={e => setEstampariaProducao(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Ítem para Produção
                  </label>
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Tamanho
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaTamanho}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaTamanho}
                    onChange={(e) => setSelectedEstampariaTamanho(e.value)}
                    placeholder="Todos os Tamanhos ..."
                  >
                  </TreeSelect>
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Modelo
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaModelo}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaModelo}
                    onChange={(e) => setSelectedEstampariaModelo(e.value)}
                    placeholder="Todos os Modelos ..."
                  >
                  </TreeSelect>
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Malha
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaMalha}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaMalha}
                    onChange={(e) => setSelectedEstampariaMalha(e.value)}
                    placeholder="Todos as Malhas ..."
                  >
                  </TreeSelect>
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Cor
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaCor}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaCor}
                    onChange={(e) => setSelectedEstampariaCor(e.value)}
                    placeholder="Todos as Cores ..."
                  >
                  </TreeSelect>
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Manga
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaManga}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaManga}
                    onChange={(e) => setSelectedEstampariaManga(e.value)}
                    placeholder="Todos as Mangas ..."
                  >
                  </TreeSelect>
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Acabamento Manga
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaMangaAcabamento}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaMangaAcabamento}
                    onChange={(e) => setSelectedEstampariaMangaAcabamento(e.value)}
                    placeholder="Todos os Acabamentos das Mangas ..."
                  >
                  </TreeSelect>
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Gola
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaGola}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaGola}
                    onChange={(e) => setSelectedEstampariaGola(e.value)}
                    placeholder="Todos as Golas ..."
                  >
                  </TreeSelect>
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Acabamento Gola
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaGolaAcabamento}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaGolaAcabamento}
                    onChange={(e) => setSelectedEstampariaGolaAcabamento(e.value)}
                    placeholder="Todos os Modelos..."
                  >
                  </TreeSelect>
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Bolso
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaBolso}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaBolso}
                    onChange={(e) => setSelectedEstampariaBolso(e.value)}
                    placeholder="Todos os Bolsos ..."
                  >
                  </TreeSelect>
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Silk
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaSilk}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaSilk}
                    onChange={(e) => setSelectedEstampariaSilk(e.value)}
                    placeholder="Todos os Silks ..."
                  >
                  </TreeSelect>
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Sublimação
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaSublimacao}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaSublimacao}
                    onChange={(e) => setSelectedEstampariaSublimacao(e.value)}
                    placeholder="Todos as Sublimações ..."
                  >
                  </TreeSelect>
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Bordado
                  </Input200px>
                  <TreeSelect
                    value={selectedEstampariaBordado}
                    className="form-control"
                    selectionMode="checkbox"
                    filter
                    emptyMessage="Nenhum registro a ser exibido"
                    options={nodeEstampariaBordado}
                    onChange={(e) => setSelectedEstampariaBordado(e.value)}
                    placeholder="Todos os Bordados ..."
                  >
                  </TreeSelect>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}
