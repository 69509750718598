import React from 'react';

import { Navigate } from 'react-router-dom';
import { get } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';

import store from '../store/store';

export default function PrivateRoute({ children, tipoUsuarioRota }) {
  const { isLoggedIn } = store.getState().auth;

  const tipoUsuarioAtual = get(store.getState().auth.user, 'tipoUsuario', '');

  if (isLoggedIn) {
    if (tipoUsuarioAtual !== tipoUsuarioRota) {
      return null;
    } else {
      return children;
    }
  }

  return <Navigate to="/login" />;
}
