/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Column } from 'primereact/column';

import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import dateFormat from 'dateformat';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, Input400px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';

export default function ConsultaNFCe(props) {
  const [isLoading, setIsLoading] = useState(false);

  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const ano = new Date().getFullYear();
  const mes = new Date().getMonth() + 1;
  const mesAnoReferencia = ('00' + (mes).toString()).slice(-2) + new Date().getFullYear().toString();
  const modelo = '65';

  // eslint-disable-next-line no-unused-vars
  const [serieEmpresa, setSerieEmpresa] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [ufEmpresa, setUfEmpresa] = useState('');

  const [empresaTipoAmbiente, setEmpresaTipoAmbiente] = useState('');

  const [textAreaInfo, setTextAreaInfo] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedNFCe, setSelectedNFCe] = useState(null);
  const [dataTableListProdutos, setDataTableListProdutos] = useState([]);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [sortField, setSortField] = useState('item');
  const [sortOrder, setSortOrder] = useState(null);
  const [showNFCeProdutosDialog, setShowNFCeProdutosDialog] = useState(false);

  const [columnsSetup] = useState([
    { field: 'data', header: 'Data', sort: true },
    { field: 'serie', header: 'Série', sort: false },
    { field: 'numeroNf', header: 'Número', sort: true },
    { field: 'chaveAcesso', header: 'Chave de Acesso', sort: true },
    { field: 'participante.razaoSocial', header: 'Participante', sort: false },
    { field: 'totalVNF', header: 'Total NFC-e', sort: false },
    { field: 'codSit', header: 'Situação', sort: false },
    { field: 'flag', header: 'Enviada', sort: true },
    { field: 'protocolo', header: 'Protocolo', sort: true },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, filtrarCnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/nfce/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(new Date(2000, 1, 1), 'dd-mm-yyyy');
        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      params = `${params}&page=${page}&size=99999`;

      const response = await axiosAdmin.get(params);

      const _response = response.data.content.filter((el) => el.flag === 'D');
      setDataTableList(_response);
      setTotalRecords(_response.length);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // somente para atualizar o text area
    setTextAreaInfo(textAreaInfo);
  }, [textAreaInfo]);

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  const handleEnviar = async (e) => {
    e.preventDefault();

    // eslint-disable-next-line consistent-return
    selectedNFCe.listaNFCeItem.forEach(el => {
      if (el.ncm === '99999999') {
        toast.info('Produto com ncm inválido no cupom fiscal');
        return true;
      }
    });

    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const id = get(selectedNFCe, 'id', 0);
      const numeroNf = get(selectedNFCe, 'numeroNf', 0);
      const cnf = get(selectedNFCe, 'cnf', 0);
      const chaveAcesso = get(selectedNFCe, 'chaveAcesso', 0);
      const serie = get(selectedNFCe, 'serie', 0);

      if (id > 0) {
        const numeroNFCeAnterior = Number(numeroNf - 1);

        let infoEnvio = '';

        infoEnvio = infoEnvio + `Emitindo NFC-e ${numeroNf}... \n`;

        // emitir o nfce em contingencia
        await axiosAdmin.get(`/nfceutil/emitirnfcecontingencia?id=${id}`).then(async () => {
          infoEnvio = infoEnvio + `NFC-e ${chaveAcesso} enviada com sucesso. \n`;
          // pegar nota anterior a inutilizar pelo id usuario, cnpjempresa e numero - 1 e flag I
          await axiosAdmin.get(`nfce/search?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresa}&flag=I&size=9999`).then(async (resNfceInutilizar) => {
            const nfceInutilizar = resNfceInutilizar.data.content.filter(el => el.numeroNf === numeroNFCeAnterior);

            // console.log('nfce a inutilizar: ', nfceInutilizar);

            // fazer os ajustes no backend
            if (nfceInutilizar.length === 1) {
              await axiosAdmin.get(`nfceutil/consultanfce?id=${nfceInutilizar[0].id}`).then(async (res) => {
                // console.log('consulta nfce na receita status: ', res.data.cstat);
                if (res.data.cstat === '217') { // nao consta na base sefaz
                  const motivo = 'NFC-e em contingência emitido para o cliente';
                  const params = `/nfceutil/inutilizarnumeracao?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresa}&ano=${ano}&mesAnoReferencia=${mesAnoReferencia}&modelo=${modelo}&serie=${serie}&numeroInicial=${nfceInutilizar[0].id}&numeroFinal=${nfceInutilizar[0].id}&motivo=${motivo}`;
                  await axiosAdmin.get(params).then((inutilizacao) => {
                    if (inutilizacao.status === 200) {
                      infoEnvio = infoEnvio + `NFC-e ${nfceInutilizar[0].numeroNf} inutilizado com sucesso. \n`;
                    }
                  });
                } else { // nota esta no site da receita
                  await axiosAdmin.get(`nfceutil/cancelarnfceporsubstituicao?id=${nfceInutilizar[0].id}&idContingencia=${id}`);
                }
              }).catch((error) => {
                // console.log('error consulta nfce: ', error);
                infoEnvio = infoEnvio + `Erro:  ${get(error, 'response.data.errors', [])} \n`;
                displayErrors(infoEnvio, dispatch, navigate, '', error.response.data);
              });
            }
          });
        }).catch((err) => {
          // console.log('err: ', err);
          infoEnvio = infoEnvio + `Erro:  ${get(err, 'response.data.errors', [])} \n`;
          displayErrors(infoEnvio, dispatch, navigate, '', 'Erro no envio da NFC-e' + err);
        });
        setTextAreaInfo(infoEnvio + '\n');
      }
    } catch (err) {
      toast.warning(displayErrors);
    } finally {
      handleFind();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
            setCnpjEmpresa(element.cnpj);
            setSerieEmpresa(element.nfeSerie);
            setUfEmpresa(element.uf);
            const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj, serie: element.nfeSerie, uf: element.uf, nfeAmbiente: element.nfeAmbiente });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }

        getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const searchFilter = { findDataInicial, findDataFinal };
    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        element.nfceAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
      }
    });
    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpjEmpresa, listaEmpresas]);

  const handleVisualizarNFCeProdutos = async (e) => {
    // console.log('e listanfceItem: ', e.listaNFCeItem);
    setDataTableListProdutos(e.listaNFCeItem);
    setShowNFCeProdutosDialog(true);
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  // eslint-disable-next-line arrow-body-style
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="row col-12">
        <div className="col-lg-3">
          <button
            type="button"
            title="Visualizar Produtos"
            className="btn-info rounded outlined"
            style={{ borderRadius: '15px' }}
            onClick={() => handleVisualizarNFCeProdutos(rowData)}
          >
            <i className="pi pi-eye"></i>
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setDataTableList([]);

    const searchFilter = { findDataInicial, findDataFinal };

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        element.nfeAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
      }
    });

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const renderTipoAmbiente = () => {
    if (empresaTipoAmbiente === 'AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL') {
      return (
        <h6 className="card-header" style={{ textAlign: 'center' }}>
          {empresaTipoAmbiente}
        </h6>
      );
    }
    return '';
  };

  const dialogNFCeProdutosFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={() => setShowNFCeProdutosDialog(false)}
      >
        <i className="pi pi-check"></i>
        Fechar
      </Button>
    </div>
  );

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Produtos Cupom </h6>}
        visible={showNFCeProdutosDialog}
        style={{ width: '90vw' }}
        onHide={() => setShowNFCeProdutosDialog(false)}
        footer={dialogNFCeProdutosFooter}
      >
        <div className="row form-elo">
          <div className="col-12">
            <DataTable
              className="datatable-nfceprodutos"
              value={dataTableListProdutos}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              dataKey="item"
              emptyMessage=" "
              scrollable="true"
              scrollHeight="350px"
              resizableColumns
            >
              <Column field="item" header="item" sort="false"></Column>
              <Column field="produto.registro" header="Registro" sort="false"></Column>
              <Column field="produto.descricao" header="Descrição" sort="false"></Column>
              <Column field="produto.ncm" header="NCM" sort="false"></Column>
              <Column field="produto.cest" header="CEST" sort="false"></Column>
              <Column field="produto.cfopVenda" header="CFOP" sort="false"></Column>
              <Column field="produto.csosnVenda" header="CSOSN" sort="false"></Column>
              <Column field="pisCst" header="CST PIS/COFINS" sort="false"></Column>
              <Column field="valor" header="Valor" sort="false"></Column>
            </DataTable>
          </div>
        </div>
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              Monitoramento de NFC-e
            </h6>
            {renderTipoAmbiente()}

          </div>
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="p-inputgroup">
                <Input400px className="p-inputgroup-addon">
                  Informações envio NFC-e
                </Input400px>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="p-inputgroup">
                <InputTextarea
                  value={textAreaInfo}
                  rows={5}
                />
              </div>
            </div>
            <div className="col-lg-3">
              {listaEmpresas.length > 1 ? (
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Empresa
                  </Input100px>
                  <Dropdown
                    options={listaEmpresas}
                    value={cnpjEmpresa}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => {
                      setCnpjEmpresa(e.target.value);
                      const consultaPrevisaoFinanceiraCnpjEmpresa = e.target.value;
                      dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
                    }}
                  />
                </div>
              ) : ('')}
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Enviar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleEnviar}
              >
                <i className="pi pi-send"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedNFCe}
            onSelectionChange={e => setSelectedNFCe(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
            <Column body={actionBodyTemplate} exportable={false} style={{ width: '50px' }}></Column>
          </DataTable>
        </div>
      </Card>
    </>
  );
}
