import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import dateFormat from 'dateformat';

import Modal from 'react-bootstrap/Modal';

import { fillColumns } from '../../../components/table';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';
import './consultaFechamentoCaixa.css';

import { formataMoeda, displayErrors } from '../../../util/diversos';
import { Input100px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';

export default function ConsultaFechamentoCaixa() {
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [versaoCaixa, setVersaoCaixa] = useState(2);
  const [resultadoCaixa, setResultadoCaixa] = useState('');
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [multiEmpresa, setMultiEmpresa] = useState(true);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [caixaVersao01List, setCaixaVersao01List] = useState([]);
  const [vendasList, setVendasList] = useState([]);
  const [recebidoCarteiraList, setRecebidoCarteiraList] = useState([]);
  const [realizadoList, setRealizadoList] = useState([]);
  const [apresentadoList, setApresentadoList] = useState([]);
  const [caixaList, setCaixaList] = useState([]);
  const [diferencaList, setDiferencaList] = useState([]);
  const [columnsSetup] = useState([
    { field: 'dataAbertura', header: 'Abertura', sort: false },
    { field: 'dataFechamento', header: 'Fechamento', sort: false },
    { field: 'numeroCaixa', header: 'Caixa', sort: false },
    { field: 'registroOperador', header: 'Operador', sort: false },
    { field: 'flag', header: 'Situação', sort: false },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, filtrarCnpjEmpresa) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/fechamentocaixa?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataAberturaInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');

        const _dataAberturaFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataAberturaInicial=${_dataAberturaInicial}&dataAberturaFinal=${_dataAberturaFinal}`;
      }

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getDataDetalhes(id) {
    try {
      setIsLoading(true);

      const { data } = await axiosAdmin.get(`/fechamentocaixa/${id}`);

      setVersaoCaixa(get(data, 'versao', 2));

      if (data.versao === 1) {
        setResultadoCaixa(data.resultado);
        setCaixaVersao01List([
          { titulo: 'Dinheiro', valor: data.vendaDinheiro },
          { titulo: 'Cheque', valor: data.vendaCheque },
          { titulo: 'Cartão Crédito', valor: data.vendaCartaoCredito },
          { titulo: 'Cartão Débito', valor: data.vendaCartaoDebito },
          { titulo: 'Boleto', valor: data.vendaBoleto },
          { titulo: 'Pagamento Digital', valor: data.vendaDigital },
          { titulo: 'Carteira', valor: data.vendaCarteira },
          { titulo: 'Valor Cancelado', valor: data.valorCancelado },
          { titulo: 'Valor Desconto Vendas', valor: data.valorDescontoVendas },
          { titulo: 'Valor Desconto Recbto', valor: data.valorDescontoRecebimento },
          { titulo: 'Saldo Final', valor: data.valorSaldoFinal },
          { titulo: 'Valor Fechamento', valor: data.valorFechamento },
          { titulo: 'Valor Abertura (+)', valor: data.valorAbertura },
          { titulo: 'Valor Reforço (+)', valor: data.valorReforco },
          { titulo: 'Valor Sangria (-)', valor: data.valorSangria },
        ]);
      } else {
        setVendasList([
          { titulo: 'Dinheiro', valor: data.vendaDinheiro },
          { titulo: 'Cheque', valor: data.vendaCheque },
          { titulo: '(-) Troco Cheque', valor: data.vendaTrocoCheque },
          { titulo: 'Cartão Crédito', valor: data.vendaCartaoCredito },
          { titulo: 'Cartão Débito', valor: data.vendaCartaoDebito },
          { titulo: 'Boleto', valor: data.vendaBoleto },
          { titulo: 'Pagamento Digital', valor: data.vendaDigital },
          { titulo: 'Ticket', valor: data.vendaTicket },
          { titulo: 'Carteira', valor: data.vendaCarteira },
        ]);

        setRecebidoCarteiraList([
          { titulo: 'Dinheiro', valor: data.recebidoCarteiraDinheiro },
          { titulo: 'Cheque', valor: data.recebidoCarteiraCheque },
          { titulo: '(-) Troco Cheque', valor: data.recebidoCarteiraTrocoCheque },
          { titulo: 'Cartão Crédito', valor: data.recebidoCarteiraCartaoCredito },
          { titulo: 'Cartão Débito', valor: data.recebidoCarteiraCartaoDebito },
          { titulo: 'Boleto', valor: data.recebidoCarteiraBoleto },
          { titulo: 'Pagamento Digital', valor: data.recebidoCarteiraDigital },
          { titulo: 'Ticket', valor: data.recebidoCarteiraTicket },
        ]);

        const totalApresentado = data.apresentadoDinheiro + data.apresentadoCheque + data.apresentadoCartaoCredito + data.apresentadoCartaoDebito + data.apresentadoBoleto
                               + data.apresentadoDigital + data.apresentadoTicket;

        setApresentadoList([
          { titulo: 'Dinheiro', valor: data.apresentadoDinheiro },
          { titulo: 'Cheque', valor: data.apresentadoCheque },
          { titulo: 'Cartão Crédito', valor: data.apresentadoCartaoCredito },
          { titulo: 'Cartão Débito', valor: data.apresentadoCartaoDebito },
          { titulo: 'Boleto', valor: data.apresentadoBoleto },
          { titulo: 'Pagamento Digital', valor: data.apresentadoDigital },
          { titulo: 'Ticket', valor: data.apresentadoTicket },
          { titulo: 'Carteira', valor: data.apresentadoCarteira },
          { titulo: 'Total Apresentado =>', valor: totalApresentado },
        ]);

        const totalRealizado = (data.vendaDinheiro + data.recebidoCarteiraDinheiro + data.valorAbertura + data.valorReforco - data.valorSangria)
                             + (data.vendaCheque + data.recebidoCarteiraCheque)
                             + (data.vendaCartaoCredito + data.recebidoCarteiraCartaoCredito)
                             + (data.vendaCartaoDebito + data.recebidoCarteiraCartaoDebito)
                             + (data.vendaBoleto + data.recebidoCarteiraBoleto)
                             + (data.vendaDigital + data.recebidoCarteiraDigital)
                             + (data.vendaTicket + data.recebidoCarteiraTicket);

        setRealizadoList([
          { titulo: 'Dinheiro', valor: data.vendaDinheiro + data.recebidoCarteiraDinheiro + data.valorAbertura + data.valorReforco - data.valorSangria },
          { titulo: 'Cheque', valor: data.vendaCheque + data.recebidoCarteiraCheque },
          { titulo: 'Cartão Crédito', valor: data.vendaCartaoCredito + data.recebidoCarteiraCartaoCredito },
          { titulo: 'Cartão Débito', valor: data.vendaCartaoDebito + data.recebidoCarteiraCartaoDebito },
          { titulo: 'Boleto', valor: data.vendaBoleto + data.recebidoCarteiraBoleto },
          { titulo: 'Pagamento Digital', valor: data.vendaDigital + data.recebidoCarteiraDigital },
          { titulo: 'Ticket', valor: data.vendaTicket + data.recebidoCarteiraTicket },
          { titulo: 'Carteira', valor: data.vendaCarteira },
          { titulo: 'Total Realizado =>', valor: totalRealizado },
        ]);

        setCaixaList([
          { titulo: 'Abertura (+)', valor: data.valorAbertura },
          { titulo: ' Reforço (+)', valor: data.valorReforco },
          { titulo: ' Sangria (-)', valor: data.valorSangria },
        ]);

        const totalDiferenca = data.diferencaDinheiro + data.diferencaCheque + data.diferencaCartaoCredito + data.diferencaCartaoDebito + data.diferencaBoleto
                               + data.diferencaDigital + data.diferencaTicket;

        setDiferencaList([
          { titulo: 'Dinheiro', valor: data.diferencaDinheiro },
          { titulo: 'Cheque', valor: data.diferencaCheque },
          { titulo: 'Cartão Crédito', valor: data.diferencaCartaoCredito },
          { titulo: 'Cartão Débito', valor: data.diferencaCartaoDebito },
          { titulo: 'Boleto', valor: data.diferencaBoleto },
          { titulo: 'Pagamento Digital', valor: data.diferencaDigital },
          { titulo: 'Ticket', valor: data.diferencaTicket },
          { titulo: 'Carteira', valor: data.diferencaCarteira },
          { titulo: 'Total Diferença =>', valor: totalDiferenca },
        ]);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleHideModal = () => {
    setModalShow(false);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ModalDetalhes(props) {
    if (!props.show) {
      return null;
    }

    const valorBodyTemplate = (rowData) => formataMoeda(rowData.valor);

    const headerTable = (title) => (
      <div className="table-header">
        {title}
      </div>
    );

    if (versaoCaixa === 1) {
      return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" dialogClassName="main-modal">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Fechamento de Caixa
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <div className="row form-elo">
              <div className="col-12">
                <DataTable
                  header={headerTable(resultadoCaixa)}
                  value={caixaVersao01List}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  selectionMode="single"
                  selectableRows
                  dataKey="titulo"
                  emptyMessage="Nenhum registro a ser exibido"
                >
                  <Column
                    field="titulo"
                    header=""
                  />
                  <Column
                    field="valor"
                    header=""
                    body={valorBodyTemplate}
                  />
                </DataTable>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleHideModal}
              type="button"
              className="btn btn-primary"
            >
              <i className="pi pi-times"></i>
              Fechar
            </button>
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" dialogClassName="main-modal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Fechamento de Caixa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row form-elo">
            <div className="col-4">
              <DataTable
                header={headerTable('Realizado')}
                value={realizadoList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="titulo"
                emptyMessage="Nenhum registro a ser exibido"
              >
                <Column
                  field="titulo"
                  header=""
                />
                <Column
                  field="valor"
                  header=""
                  body={valorBodyTemplate}
                />
              </DataTable>
            </div>
            <div className="col-4">
              <DataTable
                header={headerTable('Apresentado')}
                value={apresentadoList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="titulo"
                emptyMessage="Nenhum registro a ser exibido"
              >
                <Column
                  field="titulo"
                  header=""
                />
                <Column
                  field="valor"
                  header=""
                  body={valorBodyTemplate}
                />
              </DataTable>
            </div>
            <div className="col-4">
              <DataTable
                header={headerTable('Diferença')}
                value={diferencaList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="titulo"
                emptyMessage="Nenhum registro a ser exibido"
              >
                <Column
                  field="titulo"
                  header=""
                />
                <Column
                  field="valor"
                  header=""
                  body={valorBodyTemplate}
                />
              </DataTable>
            </div>
            <div className="row form-elo"></div>
            <div className="row form-elo">
              <div className="col-4">
                <DataTable
                  header={headerTable('Movimentação do Caixa')}
                  value={caixaList}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  selectionMode="single"
                  selectableRows
                  dataKey="titulo"
                  emptyMessage="Nenhum registro a ser exibido"
                >
                  <Column
                    field="titulo"
                    header=""
                  />
                  <Column
                    field="valor"
                    header=""
                    body={valorBodyTemplate}
                  />
                </DataTable>
              </div>
              <div className="col-4">
                <DataTable
                  header={headerTable('Vendas')}
                  value={vendasList}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  selectionMode="single"
                  selectableRows
                  dataKey="titulo"
                  emptyMessage="Nenhum registro a ser exibido"
                >
                  <Column
                    field="titulo"
                    header=""
                  />
                  <Column
                    field="valor"
                    header=""
                    body={valorBodyTemplate}
                  />
                </DataTable>
              </div>
              <div className="col-4">
                <DataTable
                  header={headerTable('Recebimento de Carteira')}
                  value={recebidoCarteiraList}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  selectionMode="single"
                  selectableRows
                  dataKey="titulo"
                  emptyMessage="Nenhum registro a ser exibido"
                >
                  <Column
                    field="titulo"
                    header=""
                  />
                  <Column
                    field="valor"
                    header=""
                    body={valorBodyTemplate}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleHideModal}
            type="button"
            className="btn btn-primary"
          >
            <i className="pi pi-times"></i>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
          setCnpjEmpresa(_cnpjEmpresaStore);
          cnpjPreenchido = true;
        }

        _listaEmpresas.forEach(element => {
          if (!cnpjPreenchido) {
            setCnpjEmpresa(element.cnpj);
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (listDropdown.length > 1) {
          setMultiEmpresa(true);
        } else {
          setMultiEmpresa(false);
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa);
  };

  const handleView = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      getDataDetalhes(id);
      setModalShow(true);
    } else {
      toast.warning('Por favor, selecione o registro para visualização');
    }
  };

  function handleCnpjEmpresa(e) {
    setCnpjEmpresa(e.target.value);

    dispatch(actions.cnpjEmpresaChange({ cnpjEmpresa: e.target.value }));

    setDataTableList([]);
  }

  function selecaoEmpresa() {
    if (multiEmpresa) {
      return (
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Empresa
              </Input100px>
              <Dropdown
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleCnpjEmpresa(e)}
              />
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="card mb-3">
        <h6 className="card-header" style={{ textAlign: 'center' }}>Fechamento de Caixa</h6>
      </div>
      <ModalDetalhes show={modalShow} onHide={handleHideModal} dialogClassName="modal-90w" />
      <Card>
        <div>
          {selecaoEmpresa()}
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial:
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => setFindDataInicial(e.value)}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final:
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => setFindDataFinal(e.value)}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Visualizar Detalhes"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleView(e)}
              >
                <i className="pi pi-eye"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            rows={10}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>
      </Card>
    </>
  );
}
