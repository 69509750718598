import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, orderBy } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData, formataDataMoment } from '../../../util/diversos';
import { Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import { fillColumns } from '../../../components/table';

import ConsultaProduto from '../produto/consultaProduto';

export default function AtualizaEstoque() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idUsuario = get(store.getState().auth.user, 'id', null);

  const [empresa, setEmpresa] = useState(null);
  const [id, setId] = useState(0);
  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [empresaCnpj, setEmpresaCnpj] = useState('');

  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [idProdutoEstoque, setIdProdutoEstoque] = useState(null);
  const [idProduto, setIdProduto] = useState(null);
  const [descricaoProduto, setDescricaoProduto] = useState('');
  const [unidadeMedida, setUnidadeMedida] = useState('');
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [registroProduto, setRegistroProduto] = useState(0);
  const [qtdeDeposito, setQtdeDeposito] = useState(0);
  const [qtdePrateleira, setQtdePrateleira] = useState(0);

  const [showCadastroInventario, setShowCadastroInventario] = useState(false);
  const [showConfirmDeleteInventario, setShowConfirmDeleteInventario] = useState(false);
  const [editandoInventario, setEditandoInventario] = useState(0);
  const [dataInventario, setDataInventario] = useState(null);
  const [quantidadeInventario, setQuantidadeInventario] = useState(0);
  const [custoInventario, setCustoInventario] = useState(0);
  const [custoMedioInventario, setCustoMedioInventario] = useState(0);

  const [sortField, setSortField] = useState('item');
  const [sortOrder, setSortOrder] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const [columnsSetup] = useState([
    { field: 'dataInventario', header: 'Data', sort: true },
    { field: 'quantidade', header: 'Quantidade', sort: false },
    { field: 'precoCusto', header: 'Preço de Custo', sort: false },
    { field: 'precoCustoMedio', header: 'Preço de Custo Médio', sort: false },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  const handleCancel = () => {
    navigate('/');
  };

  async function getDataEmpresa(idEmpresa) {
    try {
      setIsLoading(true);

      const { data } = await axiosAdmin.get(`/empresa/${idEmpresa}`);

      setEmpresaCnpj(data.cnpj);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const newTitle = 'Atualização de Estoque';

    dispatch(actions.titleHeaderChange({ newTitle }));

    let mostrarMensagemErro = true;

    async function getEmpresasDoUsuario() {
      try {
        setIsLoading(true);

        const listDropdown = [];
        const { data: _usuario } = await axiosAdmin.get(`/usuario/${idUsuario}`);

        const listaEmpresas = get(_usuario, 'listaEmpresas', []);

        let idEmpresaSelecionar = 0;

        listaEmpresas.forEach(element => {
          listDropdown.push({ label: `${element.razaoSocial}  (${element.nomeFantasia})`, value: element.id });

          if (idEmpresaSelecionar === 0 || idEmpresaSelecionar > element.id) {
            idEmpresaSelecionar = element.id;
          }
        });

        setListaEmpresa(listDropdown);
        setEmpresa(idEmpresaSelecionar);

        if (idEmpresaSelecionar > 0) {
          getDataEmpresa(idEmpresaSelecionar);
        }
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getData() {
      try {
        setIsLoading(true);

        const params = `/configuracaosistema?idUsuario=${idUsuario}`;

        const { data } = await axiosAdmin.get(params);

        setId(data.id);

        getEmpresasDoUsuario(idUsuario);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  const limparCampos = () => {
    setIdProduto(0);
    setQtdeDeposito(0);
    setQtdePrateleira(0);
    setIdProduto(0);
    setIdProdutoEstoque(0);
    setRegistroProduto(0);
    setDescricaoProduto('');
    setUnidadeMedida('');
    setDataTableList([]);
  };

  // eslint-disable-next-line consistent-return
  async function salvarRegistro() {
    try {
      setIsLoading(true);

      if (qtdeDeposito < 0) {
        toast.info('Quantidade depósito inválida');
        return false;
      }
      if (qtdePrateleira < 0) {
        toast.info('Quantidade prateleira inválida');
        return false;
      }

      if (registroProduto <= 0) {
        toast.info('Produto não selecionado.');
        return false;
      }

      let nomeEmpresa = '';
      listaEmpresa.forEach(el => {
        if (empresa === el.value) {
          nomeEmpresa = el.label.trim();
        }
      });

      // dados dos produtos
      const shippingData = {
        cnpjEmpresa,
        nomeEmpresa,
        qtdeDeposito,
        qtdePrateleira,
        idProduto,
        registroProduto,
        logModulo: 'atualizar estoque',
        logDocumento: '',
        logRotina: 'A',
        logIdUsuario: idUsuario,
      };

      if (idProdutoEstoque > 0) {
        await axiosAdmin.put(`/produtoestoque/${idProdutoEstoque}`, shippingData);
      } else {
        await axiosAdmin.post('/produtoestoque/', shippingData);
      }

      limparCampos();
      toast.success('Estoque atualizado com sucesso');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o estoque');
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErros = false;

    if (formErros) return;

    salvarRegistro();
  };

  const handleSelecionarEmpresa = (e) => {
    setEmpresa(e);

    if (e > 0) {
      getDataEmpresa(e);
    }
  };

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>Atualização de Estoque</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Atualizar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const getInventario = async (page = 0, size = 10) => {
    try {
      setIsLoading(true);

      let params = `/inventario/search?cnpjEmpresa=${cnpjEmpresa}`;

      params = `${params}&idProduto=${idProduto}`;

      params = `${params}&page=${page}&size=${size}`;

      const { data } = await axiosAdmin.get(params);

      setDataTableList(data.content);
      setTotalRecords(data.content.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const childToParentConsultaProduto = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        if (empresaCnpj.trim() !== e.cnpjEmpresa.trim()) {
          toast.info('Produto não pertence a empresa selecionada.');
          return false;
        }

        setIdProduto(e.id);
        setDescricaoProduto(e.descricao);
        setUnidadeMedida(e.unidadeMedida.sigla);
        setCnpjEmpresa(empresaCnpj);
        setRegistroProduto(e.registro);
        setIdProdutoEstoque(0);
        setQtdePrateleira(0);
        setQtdeDeposito(0);

        if (e.listaEstoques.length === 0) {
          // primeiro cadastro de estoque
        } else if (e.listaEstoques.length === 1) {
          setIdProdutoEstoque(e.listaEstoques[0].id);
          setQtdePrateleira(e.listaEstoques[0].qtdePrateleira);
          setQtdeDeposito(e.listaEstoques[0].qtdeDeposito);
        } else {
          toast.warn('Atualização de estoque deve ser realizada na consulta de produtos. Por conter filiais.');
          limparCampos();
        }
      }
      setShowConsultaProduto(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  useEffect(() => {
    if (idProduto !== null) {
      getInventario();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProduto]);

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);
  };

  useEffect(() => {
    limparCampos();
  }, [empresa]);

  function limparCamposInventario() {
    setEditandoInventario(0);
    setDataInventario(null);
    setQuantidadeInventario(0);
    setCustoInventario(0);
    setCustoMedioInventario(0);
  }

  // eslint-disable-next-line consistent-return
  const handleEditInventario = async (e) => {
    e.preventDefault();

    if (idProduto <= 0) {
      return false;
    }

    const idInventario = get(selectedItens, 'id', 0);
    const _dataInventario = converteData(get(selectedItens, 'dataInventario', null), false);
    const quantidade = get(selectedItens, 'quantidade', 0);
    const custo = get(selectedItens, 'precoCusto', 0);
    const custoMedio = get(selectedItens, 'precoCustoMedio', 0);

    if (idInventario > 0) {
      limparCamposInventario();

      setEditandoInventario(idInventario);
      setDataInventario(_dataInventario);
      setQuantidadeInventario(quantidade);
      setCustoInventario(custo);
      setCustoMedioInventario(custoMedio);

      setShowCadastroInventario(true);
    } else {
      toast.info('Selecione um inventário para edição');
      return false;
    }
  };

  const confirmDeleteInventario = async () => {
    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/inventario/${idExclusao}`);

        getInventario();

        setSelectedItens(null);

        setShowConfirmDeleteInventario(false);
        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteInventario = (e) => {
    e.preventDefault();

    const idExclusao = get(selectedItens, 'id', 0);

    if (idExclusao > 0) {
      const _itemSelecionado = dataTableList.find(element => element.id === idExclusao);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o ítem para exclusão');
        return;
      }

      setShowConfirmDeleteInventario(true);
    } else {
      toast.warning('Por favor, selecione o ítem para exclusão');
    }
  };

  const cancelDeleteInventario = () => {
    setShowConfirmDeleteInventario(false);
  };

  const confirmDialogFooterInventario = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteInventario} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteInventario} />
    </div>
  );

  const onHideCadastroInventario = () => {
    limparCamposInventario();
    setShowCadastroInventario(false);
  };

  const handleOkInventario = async () => {
    try {
      if (dataInventario === null) {
        toast.warning('Data inventário deve ser informada');
        return;
      }

      if (quantidadeInventario <= 0) {
        toast.warning('Quantidade deve ser informada');
        return;
      }

      if (custoInventario <= 0) {
        toast.warning('Custo deve ser informado');
        return;
      }

      if (custoMedioInventario <= 0) {
        toast.warning('Custo médio deve ser informado');
        return;
      }

      let _dataInventario = null;
      if (dataInventario) {
        _dataInventario = formataDataMoment(dataInventario, '-', false);
      }

      const shippingData = {
        dataInventario: _dataInventario,
        cnpjEmpresa,
        idProduto,
        precoCusto: custoInventario,
        precoCustoMedio: custoMedioInventario,
        quantidade: quantidadeInventario,
        registroProduto,
        totalCusto: (quantidadeInventario * custoInventario),
        totalCustoMedio: (quantidadeInventario * custoMedioInventario),
      };

      if (editandoInventario > 0) {
        await axiosAdmin.put(`/inventario/${editandoInventario}`, shippingData);
      } else {
        await axiosAdmin.post('/inventario', shippingData);
      }

      toast.success('Inventário salvo com sucesso');

      setSelectedItens(null);
      getInventario();
      limparCamposInventario();
      setShowCadastroInventario(false);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar/incluir o inventário');
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleIncluirInventario = () => {
    if (idProduto <= 0) {
      return false;
    }

    setEditandoInventario(0);
    limparCamposInventario();
    setShowCadastroInventario(true);
  };

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteInventario}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterInventario}
        onHide={() => setShowConfirmDeleteInventario(false)}
      >
        <p>
          Excluir o ítem?
        </p>
      </Dialog>

      <Dialog
        header="Consulta de Produtos"
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaProduto()}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Cadastro Inventário"
        visible={showCadastroInventario}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroInventario()}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Inventário:
              </Input155px>
              <Calendar
                disabled={editandoInventario}
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataInventario}
                onChange={(e) => setDataInventario(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Quantidade
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={3}
                value={quantidadeInventario}
                onValueChange={(e) => setQuantidadeInventario(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Preço de Custo
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={3}
                value={custoInventario}
                onValueChange={(e) => setCustoInventario(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Preço de Custo Médio
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={3}
                value={custoMedioInventario}
                onValueChange={(e) => setCustoMedioInventario(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkInventario}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroInventario(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa
              </Input155px>
              <Dropdown
                options={listaEmpresa}
                value={empresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleSelecionarEmpresa(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Produto
              </Input155px>
              <InputText
                disabled
                value={descricaoProduto}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaProduto(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Unidade
              </Input155px>
              <InputText
                disabled
                value={unidadeMedida}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Qtde. Depósito
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={3}
                value={qtdeDeposito}
                onValueChange={(e) => setQtdeDeposito(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Qtde. Prateleira
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={3}
                value={qtdePrateleira}
                onValueChange={(e) => setQtdePrateleira(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="card">
            <DataTable
              header="Inventários"
              value={dataTableList}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField={sortField}
              sortOrder={sortOrder}
              onSort={onSort}
              selectionMode="single"
              selection={selectedItens}
              onSelectionChange={e => setSelectedItens(e.value)}
              selectableRows
              dataKey="id"
              paginator
              lazy
              first={lazyParams.first}
              rows={lazyParams.rows}
              totalRecords={totalRecords}
              onPage={onPage}
              emptyMessage="Nenhum registro a ser exibido"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{currentPage} de {totalPages}"
              rowsPerPageOptions={[10, 25, 50]}
            >
              {fillColumns(columnsSetup)}
            </DataTable>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              title="Inclusão Inventário"
              className="btn btn-primary"
              style={{ borderRadius: '15px' }}
              onClick={() => handleIncluirInventario()}
            >
              <i className="pi pi-plus"></i>
            </button>
            <button
              type="button"
              title="Alterar Ítem"
              className="btn btn-warning"
              style={{ borderRadius: '15px' }}
              onClick={(e) => handleEditInventario(e)}
            >
              <i className="pi pi-pencil"></i>
            </button>
            <button
              type="button"
              title="Cancelar Ítem"
              className="btn btn-danger"
              style={{ borderRadius: '15px' }}
              onClick={(e) => handleDeleteInventario(e)}
            >
              <i className="pi pi-trash"></i>
            </button>
          </div>
        </div>
        <div className="row form-elo">

        </div>
      </Card>
    </div>
  );
}
