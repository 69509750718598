import React, { useState, useEffect, useRef } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, orderBy } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input155px, Input200px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import { obterListaTipoMovimentacaoProduto } from '../../../services/tipoMovimentacaoProduto';
import { fillColumns } from '../../../components/table';

import ConsultaProduto from '../produto/consultaProduto';

export default function CadastroMovimentacaoProduto({ payloadCadastro, childToParentConsulta }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const UseFocus = () => {
    const htmlElRef = useRef(null);
    // eslint-disable-next-line no-unused-expressions
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus(); };

    return [htmlElRef, setFocus];
  };

  const [inputProdutoRegistroFocus, setInputProdutoRegistroFocus] = UseFocus();
  const [inputQuantidadeFocus, setInputQuantidadeFocus] = UseFocus();
  const [buttonOkItem, setButtonOkItem] = UseFocus();
  const [buttonInclusaoItem, setButtonInclusaoItem] = UseFocus();

  const id = get(payloadCadastro, 'id', 0);

  const [observacao, setObservacao] = useState('');
  const [tipo, setTipo] = useState('ENTRADAESTOQUE');
  const [cnpjEmpresaOrigem, setCnpjEmpresaOrigem] = useState(null);
  const [cnpjEmpresaDestino, setCnpjEmpresaDestino] = useState(null);

  const [listaEmpresas, setListaEmpresas] = useState([]);

  const [produtoConsulta, setProdutoConsulta] = useState(null);
  const [editandoItem, setEditandoItem] = useState(0);
  const [itemIdProduto, setItemIdProduto] = useState(0);
  const [itemRegistroProduto, setItemRegistroProduto] = useState(0);
  const [itemDescricao, setItemDescricao] = useState('');
  const [itemUnidadeMedida, setItemUnidadeMedida] = useState('');
  const [itemQuantidade, setItemQuantidade] = useState(0);
  const [listaItem, setListaItem] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [showCadastroItem, setShowCadastroItem] = useState(false);
  const [showConfirmDeleteItem, setShowConfirmDeleteItem] = useState(false);

  const [showConsultaProduto, setShowConsultaProduto] = useState(false);

  const listaTipoMovimentacaoProduto = obterListaTipoMovimentacaoProduto();

  const [columnsSetupItem] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'itemRegistroProduto', header: 'Registro', sort: true },
    { field: 'itemDescricao', header: 'Descrição', sort: true },
    { field: 'itemQuantidade', header: 'Quantidade', sort: true },
  ]);

  useEffect(() => {
    let newTitle = 'Movimentação de Produtos - Entrada/Transferência';
    if (id) {
      newTitle = 'Movimentação de Produtos - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/movimentacaoproduto/${id}`);

        setCnpjEmpresaOrigem(data.empresaOrigem ? data.empresaOrigem.cnpj : null);
        setCnpjEmpresaDestino(data.empresaDestino ? data.empresaDestino.cnpj : null);
        setObservacao(data.observacao);
        setTipo(data.tipo);

        const _listaItem = [];
        data.listaMovimentacaoProdutoItem.forEach(el => {
          _listaItem.push({
            item: el.item,
            idProduto: el.produto.id,
            itemRegistroProduto: el.produto.registro,
            itemDescricao: el.produto.descricao.trim(),
            itemQuantidade: el.quantidade,
            itemUnidadeMedida: el.produto.unidadeMedida.sigla,
          });
        });

        const listaItemOrdenada = orderBy(_listaItem, 'itemDescricao', 'asc');

        setListaItem(listaItemOrdenada);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', null);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        _listaEmpresas.forEach(element => {
          listDropdown.push(
            {
              label: element.razaoSocial,
              value: element.cnpj,
              id: element.id,
            });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    verificarSeMultiEmpresa();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', null);

      const tipoAcesso = get(store.getState().auth.user, 'tipoAcesso', null);
      if (tipo === 'ENTRADAESTOQUE' && tipoAcesso !== 'TOTAL') {
        toast.info('Usuário não autorizado a entrada de Produtos, somente a transferências.');
        return false;
      }

      const itens = [];
      let item = 1;
      const listaItemOrdenada = orderBy(listaItem, 'itemDescricao', 'asc');
      listaItemOrdenada.forEach(el => {
        itens.push({
          item: item++,
          idProduto: el.idProduto,
          quantidade: el.itemQuantidade,
        });
      });

      const shippingData = {
        idUsuario,
        cnpjEmpresaOrigem,
        cnpjEmpresaDestino,
        tipo,
        observacao,
        listaMovimentacaoProdutoItem: itens,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/movimentacaoproduto/${id}`, shippingData), 'data', {});
        toast.success('Movimentação de Produto atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/movimentacaoproduto', shippingData), 'data', {});
        toast.success('Movimentação de Produto cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = (e) => {
    e.preventDefault();

    if (tipo === 'ENTRADAESTOQUE') {
      setCnpjEmpresaOrigem(null);

      if (cnpjEmpresaDestino === null) {
        toast.warn('Empresa Destino não selecionada');
        return false;
      }
    }

    if (tipo === 'TRANSFERENCIA') {
      if (cnpjEmpresaOrigem === cnpjEmpresaDestino && cnpjEmpresaOrigem !== null && cnpjEmpresaDestino !== null) {
        toast.warn('Empresa Origem e Destino não podem ser a mesma');
        return false;
      }
    }

    if (tipo.substring(0, 5) === 'SAIDA') {
      setCnpjEmpresaDestino(null);
      if (cnpjEmpresaOrigem === null) {
        toast.warn('Empresa Origem não selecionada');
        return false;
      }
    }

    if (listaItem.length <= 0) {
      toast.warn('Lista Produtos vazia');
      return false;
    }

    salvarRegistro();
  };

  const limpaCamposCadastroItem = () => {
    setItemRegistroProduto('');
    setItemDescricao('');
    setItemUnidadeMedida('');
    setItemQuantidade(0);
  };

  const verificarItemListaItem = (idProduto) => {
    let existeItem = false;
    listaItem.forEach(element => {
      if (idProduto === element.idProduto) {
        existeItem = true;
      }
    });

    return existeItem;
  };

  // eslint-disable-next-line consistent-return
  const childToParentConsultaProduto = (e) => {
    setProdutoConsulta(e);
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (verificarItemListaItem(idElement)) {
        toast.warn('Produto já incluído na movimentação');
        limpaCamposCadastroItem();
        return false;
      }

      const idUsuario = get(store.getState().auth.user, 'id', null);

      if (idUsuario === e.idUsuario) {
        if (e.id) {
          // console.log('e child to produto: ', e);
          setItemIdProduto(e.id);
          setItemRegistroProduto(e.registro);
          setItemDescricao(e.descricao);
          setItemUnidadeMedida(e.unidadeMedida.sigla);
        }
        setShowConsultaProduto(false);
        setInputQuantidadeFocus();
      } else {
        toast.warn('Produto selecionado não pertence a empresa');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const handleNovoItem = () => {
    limpaCamposCadastroItem();
    setShowCadastroItem(true);
  };

  const handleEditItem = (e) => {
    e.preventDefault();

    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItem.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      // console.log('item selecionado: ', _itemSelecionado);

      setEditandoItem(item);

      limpaCamposCadastroItem();

      setItemIdProduto(_itemSelecionado.idProduto);
      setItemRegistroProduto(_itemSelecionado.itemRegistroProduto);
      setItemDescricao(_itemSelecionado.itemDescricao);
      setItemUnidadeMedida(_itemSelecionado.itemUnidadeMedida);
      setItemQuantidade(_itemSelecionado.itemQuantidade);

      setShowCadastroItem(true);
    }
  };

  const handleDeleteItem = (e) => {
    e.preventDefault();

    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItem.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteItem(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleOkItem = async () => {
    if (itemIdProduto <= 0) {
      toast.warning('Produto deve ser informado');
      return false;
    }

    if (itemDescricao === '') {
      toast.warning('Produto deve ser informado');
      return false;
    }

    if (itemQuantidade <= 0) {
      toast.warning('Quantidade deve ser informada');
      setInputQuantidadeFocus();
      return false;
    }

    if (tipo === 'TRANSFERENCIA'
        || tipo === 'SAIDAGARANTIA'
        || tipo === 'SAIDAACERTOESTOQUE'
        || tipo === 'SAIDAPERDA'
        || tipo === 'SAIDAFURTO'
        || tipo === 'SAIDACONSUMOPROPRIO'
    ) {
      // eslint-disable-next-line consistent-return
      let existe = false;
      let quantidadeInsuficiente = 0;
      if (editandoItem && editandoItem > 0) {
        const params = `/produto/${itemIdProduto}`;

        await axiosAdmin.get(params).then((response) => {
          response.data.listaEstoques.forEach(el => {
            if (el.cnpjEmpresa === cnpjEmpresaOrigem) {
              existe = true;
              if (Number(itemQuantidade) > Number(el.qtdePrateleira)) {
                quantidadeInsuficiente = el.qtdePrateleira;
              }
            }
          });
        });
      } else {
        produtoConsulta.listaEstoques.forEach(el => {
          if (el.cnpjEmpresa === cnpjEmpresaOrigem) {
            existe = true;
            if (Number(itemQuantidade) > Number(el.qtdePrateleira)) {
              quantidadeInsuficiente = el.qtdePrateleira;
            }
          }
        });
      }

      if (!existe) {
        toast.warning('Empresa de origem não possui estoque deste produto');
        return false;
      }

      if (quantidadeInsuficiente) {
        toast.warning('Quantidade do produto na origem insuficiente para transferência: ' + quantidadeInsuficiente);
        return false;
      }
    }

    const _listaItem = listaItem;

    let item = 0;
    listaItem.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItem && editandoItem > 0) {
      item = editandoItem;

      const index = _listaItem.indexOf(_listaItem.find(element => element.item === editandoItem));

      if (index >= 0) {
        _listaItem.splice(index, 1);
      }
    }

    _listaItem.push({
      item,
      idProduto: itemIdProduto,
      itemRegistroProduto,
      itemDescricao: itemDescricao.trim(),
      itemQuantidade,
      itemUnidadeMedida,
    });

    const listaItemOrdenada = orderBy(_listaItem, 'item', 'asc');

    setListaItem([]);
    setListaItem(listaItemOrdenada);

    limpaCamposCadastroItem();
    setShowCadastroItem(false);
    setButtonInclusaoItem();
  };

  const onHideCadastroItem = () => {
    setShowCadastroItem(false);
  };

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  const confirmDeleteItem = () => {
    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _listaItem = listaItem;

      const index = _listaItem.indexOf(_listaItem.find(element => element.item === item));

      if (index >= 0) {
        _listaItem.splice(index, 1);

        _listaItem.forEach(element => {
          if (element.item > item) {
            const _index = _listaItem.indexOf(element);

            _listaItem[_index].item = _listaItem[_index].item - 1;
          }
        });

        setListaItem(_listaItem);

        setItemIdProduto(0);
        setShowConfirmDeleteItem(false);
      }
    }
  };

  const cancelDeleteItem = () => {
    setShowConfirmDeleteItem(false);
  };

  const confirmDialogFooterItem = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItem} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItem} />
    </div>
  );

  // eslint-disable-next-line consistent-return
  async function handleBuscarProdutoByIdUsuarioEmpresaDestino(e) {
    try {
      setIsLoading(true);

      if (e.length <= 0) {
        setItemDescricao('');
        return false;
      }

      const idUsuario = get(store.getState().auth.user, 'id', null);

      let params = `/produto/search?idUsuario=${idUsuario}`;

      params = `${params}&registro=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (verificarItemListaItem(response.data.content[0].id)) {
        toast.warn('Produto já incluído na movimentação');
        limpaCamposCadastroItem();
        return false;
      }

      if (response.data) {
        setProdutoConsulta(response.data.content[0]);
        setItemIdProduto(response.data.content[0].id);
        setItemDescricao(response.data.content[0].descricao);
        setItemUnidadeMedida(response.data.content[0].unidadeMedida.sigla);
        setInputQuantidadeFocus();
      } else {
        limpaCamposCadastroItem();
        toast.info('Produto não encontrado');
        setInputProdutoRegistroFocus();
      }
    } catch (err) {
      setItemDescricao('');
      setInputProdutoRegistroFocus();
      toast.info('Produto não encontrado');

      // displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const titulo = () => (id ? 'Movimentação de Produto - Alteração' : 'Movimentação de Produto - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Produtos"
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaProduto()}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} />
      </Dialog>

      <Dialog
        header="Inclusão Item"
        visible={showCadastroItem}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroItem()}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Produto*
              </Input200px>
              <InputText
                inputRef={inputProdutoRegistroFocus}
                autoFocus
                onFocus={(e) => e.target.select()}
                disabled={editandoItem}
                value={itemRegistroProduto}
                onChange={e => setItemRegistroProduto(e.target.value)}
                onBlur={e => {
                  handleBuscarProdutoByIdUsuarioEmpresaDestino(e.target.value);
                  // setInputQuantidadeFocus();
                }}
                onKeyDown={async (e) => {
                  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                    handleBuscarProdutoByIdUsuarioEmpresaDestino(e.target.value);
                  }
                }}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaProduto(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <InputText
                disabled
                maxLength={255}
                value={itemDescricao}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Unidade*
              </Input200px>
              <InputText
                disabled
                value={itemUnidadeMedida}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Quantidade*
              </Input200px>
              <InputNumber
                inputRef={inputQuantidadeFocus}
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                minFractionDigits={4}
                maxFractionDigits={4}
                value={itemQuantidade}
                onValueChange={(e) => setItemQuantidade(e.target.value)}
                onKeyDown={async (e) => {
                  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                    setButtonOkItem();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              ref={buttonOkItem}
              type="button"
              className="btn btn-success"
              // title="Confirma Dados do Item"
              onClick={handleOkItem}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroItem(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTipoMovimentacaoProduto}
                value={tipo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Observação
              </Input155px>
              <InputText
                maxLength={100}
                value={observacao}
                onChange={e => setObservacao(e.target.value)}
              />
            </div>
          </div>
        </div>
        {listaEmpresas.length > 1 && tipo !== 'ENTRADAESTOQUE' ? (
          <div className="row form-elo">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Empresa Origem
                </Input155px>
                <Dropdown
                  options={listaEmpresas}
                  value={cnpjEmpresaOrigem}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => setCnpjEmpresaOrigem(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : ('')}
        {listaEmpresas.length > 1 && tipo.substring(0, 5) !== 'SAIDA' ? (
          <div className="row form-elo">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Empresa Destino
                </Input155px>
                <Dropdown
                  options={listaEmpresas}
                  value={cnpjEmpresaDestino}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => setCnpjEmpresaDestino(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : ('')}
        <div className="row form-elo">
          <div className="estamparia-pedido-item">
            <DataTable
              header="Produtos"
              value={listaItem}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              selection={selectedItens}
              onSelectionChange={e => setSelectedItens(e.value)}
              selectableRows
              dataKey="item"
              emptyMessage="Nenhum Item cadastrado"
            >
              {fillColumns(columnsSetupItem)}
            </DataTable>
          </div>
          <br />
          <div className="row form-elo">
            <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button
                ref={buttonInclusaoItem}
                type="button"
                title="Inclusão Item"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={() => {
                  setEditandoItem(0);
                  handleNovoItem();
                }}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Alterar Item"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEditItem(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Cancelar Item"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDeleteItem(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteItem}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterItem}
        onHide={() => setShowConfirmDeleteItem(false)}
      >
        <p>
          Excluir o item?
        </p>
      </Dialog>
    </div>
  );
}
