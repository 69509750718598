/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { ToggleButton } from 'primereact/togglebutton';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';
import { Base64 } from 'js-base64';
import { save } from 'save-file';

import dateFormat from 'dateformat';

import * as actions from '../../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../../util/diversos';
import { fillColumns } from '../../../../components/table';
import { Input100px, Input200px } from '../../../../styles/inputGroup';

import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Card from '../../../../components/card';
import Loading from '../../../loading';
import CadastroNFePropria from '../cadastroNFe';

export default function EnvioNFePropria() {
  const [isLoading, setIsLoading] = useState(false);
  const [empresaTipoAmbiente, setEmpresaTipoAmbiente] = useState('');
  const [contingencia, setContingencia] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro] = useState({});
  const [columnsSetup] = useState([
    { field: 'data', header: 'Data', sort: true },
    { field: 'numeroNf', header: 'Número', sort: true },
    { field: 'mod', header: 'Modelo', sort: false },
    { field: 'serie', header: 'Série', sort: false },
    { field: 'participante.razaoSocial', header: 'Participante', sort: false },
    { field: 'totalVNF', header: 'Total NF-e', sort: false },
    { field: 'codSit', header: 'Situação', sort: false },
    { field: 'flag', header: 'Enviada', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, filtrarCnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/nfe/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat('01-01-2000', 'dd-mm-yyyy');

        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}&emissaoPropria=true&flag=D`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        element.nfeAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
      }
    });
  }, [cnpjEmpresa, listaEmpresas]);

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);
    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
            setCnpjEmpresa(element.cnpj);
            element.nfeAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
            const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj, nfeAmbiente: element.nfeAmbiente, id: element.id });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }

        getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  async function salvarArquivo(conteudo, nome) {
    await save(conteudo, nome);
  }

  const downloadPdf = (response, chaveAcesso) => {
    const byteCharacters = Base64.atob(response.data);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const blobFile = new Blob([byteArray], { type: 'application/pdf' });

    salvarArquivo(blobFile, chaveAcesso + '.pdf');
  };

  async function handleDownloadArquivos(res) {
    try {
      setIsLoading(true);

      // eslint-disable-next-line prefer-destructuring
      const chaveAcesso = res.data.chaveAcesso;

      if (chaveAcesso !== null) {
        const response = await axiosAdmin.get(`/nfeutil/downloaddanfe?chaveacesso=${chaveAcesso}`);
        downloadPdf(response, chaveAcesso);
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    } finally {
      setIsLoading(false);
    }
  }

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  // eslint-disable-next-line consistent-return
  const handleEnviar = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const id = get(selectedItens, 'id', 0);

      if (id > 0) {
        let empresaId = 0;

        listaEmpresas.forEach(element => {
          if (element.value === cnpjEmpresa) {
            empresaId = element.id;
          }
        });

        const isLicencaValida = await licencaValida(empresaId);
        if (!isLicencaValida) {
          toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
          navigate('/pagamento');
          return false;
        }

        await axiosAdmin.get(`/nfeutil/emitirnfe?id=${id}&contingencia=${contingencia}`).then(async () => {
          const response = await axiosAdmin.get(`/nfe/${id}`);

          handleDownloadArquivos(response);

          toast.success('NFe enviada com sucesso.');
        });
        handleFind();
      } else {
        toast.warning('Por favor, selecione o registro para envio');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível enviar a nota fiscal');
    } finally {
      setIsLoading(false);
    }
  };

  const visualizarNfe = (response) => {
    const byteCharacters = Base64.atob(response.data);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const blobFile = new Blob([byteArray], { type: 'application/pdf' });

    salvarArquivo(blobFile, 'visualizacaoNFe.pdf');
  };

  const handleVisualizarNFe = async () => {
    try {
      setIsLoading(true);

      const id = get(selectedItens, 'id', 0);

      if (id <= 0) {
        toast.warn('Por favor, selecione o registro para visualização');
        return;
      }

      if (id > 0) {
        const response = await axiosAdmin.get(`/nfeutil/visualizarnfe?id=${id}`);
        visualizarNfe(response);
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível visualizar a NFe');
    } finally {
      setIsLoading(false);
    }
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentConsulta = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  const renderTipoAmbiente = () => {
    if (empresaTipoAmbiente === 'AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL') {
      return (
        <h6 className="card-header" style={{ textAlign: 'center' }}>
          {empresaTipoAmbiente}
        </h6>
      );
    }
    return '';
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroNFePropria payloadCadastro={payloadCadastro} childToParentConsulta={childToParentConsulta} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              Nota Fiscal Eletrônica - Envio
            </h6>
            {renderTipoAmbiente()}
          </div>
          <div className="row align-items-center">
            <div className="col-3">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Enviar em Contingência
                </Input200px>
                <ToggleButton
                  onLabel="Sim"
                  offLabel="Não"
                  onIcon="pi pi-check"
                  offIcon="pi pi-times"
                  checked={contingencia}
                  onChange={(e) => setContingencia(e.value)}
                />
              </div>
            </div>
            {listaEmpresas.length > 1 ? (
              <div className="col-lg-7">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Empresa
                  </Input100px>
                  <Dropdown
                    options={listaEmpresas}
                    value={cnpjEmpresa}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => {
                      setCnpjEmpresa(e.target.value);
                      const consultaPrevisaoFinanceiraCnpjEmpresa = e.target.value;
                      dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
                    }}
                  />
                </div>
              </div>
            ) : (<div className="col-lg-7"></div>)}
            <div className="col-lg-2">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Visualizar NFe"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleVisualizarNFe(e)}
              >
                <i className="pi pi-eye"></i>
              </button>
              <button
                type="button"
                title="Enviar NF-e"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEnviar(e)}
              >
                <i className="pi pi-send"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>
      </Card>
    </>
  );
}
