/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get, orderBy } from 'lodash';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import { displayErrors } from '../../../util/diversos';

export default function PdfMovimentacaoProduto({ payloadPrint, finishPrintMovimentacaoProduto }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadPrint, 'id', 0);

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  function headRows() {
    return [
      { item: 'item', registro: 'Registro', descricao: 'Descricao', quantidade: 'Qtde' },
    ];
  }

  const [headerTabelaImpressao] = useState(headRows());

  useEffect(() => {
    async function getData(img) {
      // eslint-disable-next-line new-cap

      try {
        const usuario = await axiosAdmin.get(`/usuario/${idUsuario}`);
        let cnpjEmpresa = usuario.data.listaEmpresas[0].cnpj;
        if (cnpjEmpresa.length === 14) {
          cnpjEmpresa = cnpjEmpresa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

        const { data } = await axiosAdmin.get(`/movimentacaoproduto/${id}`);

        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultamovimentacaoproduto', '');
        }

        let tipoMovimentacao = '';
        if (data.tipo === 'ENTRADAESTOQUE') {
          tipoMovimentacao = 'Entrada Estoque';
        }
        if (data.tipo === 'TRANSFERENCIA') {
          tipoMovimentacao = 'Transferencia Estoque';
        }
        if (data.tipo === 'SAIDAACERTOESTOQUE') {
          tipoMovimentacao = 'Saida Acerto Estoque';
        }
        if (data.tipo === 'SAIDACONSUMOPROPRIO') {
          tipoMovimentacao = 'Saida Consumo Próprio';
        }
        if (data.tipo === 'SAIDAFURTO') {
          tipoMovimentacao = 'Saida Furto';
        }
        if (data.tipo === 'SAIDAPERDA') {
          tipoMovimentacao = 'Saida Perda';
        }
        if (data.tipo === 'SAIDAGARANTIA') {
          tipoMovimentacao = 'Saida Garantia';
        }

        const empresaOrigem = data.empresaOrigem ? data.empresaOrigem : null;
        const empresaDestino = data.empresaDestino ? data.empresaDestino : null;

        const result = [];

        let dados = {};
        let item = 1;
        const _listaMovimentacaoProdutoItem = orderBy(data.listaMovimentacaoProdutoItem, 'produto.descricao', 'asc');
        _listaMovimentacaoProdutoItem.forEach(el => {
          dados.item = item++;
          dados.registro = el.produto.registro;
          dados.descricao = el.produto.descricao.trim();
          dados.quantidade = el.quantidade;
          result.push({ ...dados });
        });

        dados = {
          item: '',
          registro: '',
          descricao: '',
          quantidade: '',
        };

        result.push({ ...dados });

        dados = {
          item: '',
          registro: '',
          descricao: '------------------------------------',
          quantidade: '',
        };

        result.push({ ...dados });

        dados = {
          item: '',
          registro: '',
          descricao: 'Assinatura Responsavel',
          quantidade: '',
        };

        result.push({ ...dados });

        // eslint-disable-next-line new-cap
        let tamanhoImpressao = 110;
        if (result.length > 6) {
          tamanhoImpressao += 5 * (result.length - 6);
        }

        // eslint-disable-next-line new-cap
        const doc = new jsPDF('p', 'mm', [80, tamanhoImpressao]);
        doc.addFont('Helvetica', 'Helvetica', 'normal');
        doc.addFont('Helvetica-bold', 'Helvetica Bold', 'bold');
        doc.setFont('Helvetica');
        doc.internal.pageSize.setHeight(tamanhoImpressao + 30);

        doc.autoTable({
          styles: { fontSize: 8, font: 'Helvetica-bold', fontStyle: 'bold' },
          margin: { top: 31, left: 3, right: 3 },
          theme: 'plain',
          head: headerTabelaImpressao,
          body: result,
          didDrawPage: () => {
            // Header
            // doc.rect(3, 2, 74, 26);
            if (!img.complete) {
              doc.addImage('logo.png', 'JPEG', 4, 4, 13, 13);
            } else {
              doc.addImage(img, 'JPEG', 4, 4, 13, 13);
            }
            doc.setFontSize(10);
            doc.text('Movimentacao Produto: ', 25, 7);
            doc.text(id.toString(), 65, 7);

            doc.text('Tipo: ', 25, 12);
            doc.text(tipoMovimentacao, 35, 12);

            doc.text('Data: ', 25, 17);
            doc.text(`${('00' + data.data[2]).slice(-2)}/${('00' + data.data[1]).slice(-2)}/${data.data[0]}`, 35, 17);

            if (tipoMovimentacao === 'Entrada Estoque') {
              doc.text('Destino: ', 4, 24);
              doc.text(empresaDestino.nomeFantasia ? empresaDestino.nomeFantasia : empresaDestino.razaoSocial, 20, 24);
            }

            if (tipoMovimentacao === 'Transferencia Estoque') {
              doc.text('Origem: ', 4, 22);
              doc.text(empresaOrigem.nomeFantasia ? empresaOrigem.nomeFantasia : empresaOrigem.razaoSocial, 20, 22);
              doc.text('Destino: ', 4, 27);
              doc.text(empresaDestino.nomeFantasia ? empresaDestino.nomeFantasia : empresaDestino.razaoSocial, 20, 27);
            }
          },
        });

        window.open(doc.output('bloburl'));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('erro', err);
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        // funcao no pai pra fechar a tela de impressao
        finishPrintMovimentacaoProduto();
      }
    }

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(img);
    };
    loadImage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, navigate]);

  return (
    <div></div>
  );
}
