/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmail } from 'validator';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { ToggleButton } from 'primereact/togglebutton';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input155px, Input200px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroOperador({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [isLoading, setIsLoading] = useState(false);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaRepeticao, setSenhaRepeticao] = useState('');
  const [tipoAcesso, setTipoAcesso] = useState('TOTAL');
  const [nivel, setNivel] = useState('00000000000000000000');
  const [listaTipoAcesso] = useState([
    { label: 'Total', value: 'TOTAL' },
    { label: 'Supervisor', value: 'SUPERVISOR' },
    { label: 'Funcionário', value: 'FUNCIONARIO' },
    { label: 'Vendas', value: 'VENDAS' },
    { label: 'Personalizado', value: 'PERSONALIZADO' },
  ]);

  const [aberturaCaixa, setAberturaCaixa] = useState(false);
  const [reforco, setReforco] = useState(false);
  const [sangria, setSangria] = useState(false);
  const [acertoCobranca, setAcertoCobranca] = useState(false);
  const [alteracaoPedido, setAlteracaoPedido] = useState(false);
  const [reemissaoPedido, setReemissaoPedido] = useState(false);
  const [cancelamentoPedido, setCancelamentoPedido] = useState(false);
  const [transferencia, setTransferencia] = useState(false);
  const [abrirGaveta, setAbrirGaveta] = useState(false);
  const [autorizacaoVenda, setAutorizacaoVenda] = useState(false);
  const [autorizacaoDesconto, setAutorizacaoDesconto] = useState(false);
  const [devolucao, setDevolucao] = useState(false);
  const [retirarInadimplencia, setRetirarInadimplencia] = useState(false);
  const [mobileOffline, setMobileOffline] = useState(false);
  const [somenteConsultaAC, setSomenteConsultaAC] = useState(false);
  const [verCustoVenda, setVerCustoVenda] = useState(false);
  const [autorizarNegociacao, setAutorizarNegociacao] = useState(false);
  const [cancelamentoF3, setCancelamentoF3] = useState(false);
  const [autorizarVendaConsignada, setAutorizarVendaConsignada] = useState(false);
  const [controleEntrega, setControleEntrega] = useState(false);

  useEffect(() => {
    let newTitle = 'Operador - Cadastro';
    if (id) {
      newTitle = 'Operador - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    const desmontarNivel = (nivel) => {
      nivel[0] === '1' ? setAberturaCaixa(true) : '';
      nivel[1] === '1' ? setReforco(true) : '';
      nivel[2] === '1' ? setSangria(true) : '';
      nivel[3] === '1' ? setAcertoCobranca(true) : '';
      nivel[4] === '1' ? setAlteracaoPedido(true) : '';
      nivel[5] === '1' ? setReemissaoPedido(true) : '';
      nivel[6] === '1' ? setCancelamentoPedido(true) : '';
      nivel[7] === '1' ? setTransferencia(true) : '';
      nivel[8] === '1' ? setAbrirGaveta(true) : '';
      nivel[9] === '1' ? setAutorizacaoVenda(true) : '';
      nivel[10] === '1' ? setAutorizacaoDesconto(true) : '';
      nivel[11] === '1' ? setDevolucao(true) : '';
      nivel[12] === '1' ? setRetirarInadimplencia(true) : '';
      nivel[13] === '1' ? setMobileOffline(true) : '';
      nivel[14] === '1' ? setSomenteConsultaAC(true) : '';
      nivel[15] === '1' ? setVerCustoVenda(true) : '';
      nivel[16] === '1' ? setAutorizarNegociacao(true) : '';
      nivel[17] === '1' ? setCancelamentoF3(true) : '';
      nivel[18] === '1' ? setAutorizarVendaConsignada(true) : '';
      nivel[19] === '1' ? setControleEntrega(true) : '';
    };

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/operador/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        if (data.idUsuario !== idUsuario) {
          navigate('/consultaoperador');
          toast.error('Acesso negado');
        }

        setEmail(data.email);
        setNome(data.nome);
        setSenha('');
        setSenhaRepeticao('');
        setTipoAcesso(data.tipoAcesso);
        setNivel(data.nivel);

        desmontarNivel(data.nivel);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  const montarNivel = () => {
    let stringNivel = '';

    aberturaCaixa ? stringNivel += '1' : stringNivel += '0';
    reforco ? stringNivel += '1' : stringNivel += '0';
    sangria ? stringNivel += '1' : stringNivel += '0';
    acertoCobranca ? stringNivel += '1' : stringNivel += '0';
    alteracaoPedido ? stringNivel += '1' : stringNivel += '0';
    reemissaoPedido ? stringNivel += '1' : stringNivel += '0';
    cancelamentoPedido ? stringNivel += '1' : stringNivel += '0';
    transferencia ? stringNivel += '1' : stringNivel += '0';
    abrirGaveta ? stringNivel += '1' : stringNivel += '0';
    autorizacaoVenda ? stringNivel += '1' : stringNivel += '0';
    autorizacaoDesconto ? stringNivel += '1' : stringNivel += '0';
    devolucao ? stringNivel += '1' : stringNivel += '0';
    retirarInadimplencia ? stringNivel += '1' : stringNivel += '0';
    mobileOffline ? stringNivel += '1' : stringNivel += '0';
    somenteConsultaAC ? stringNivel += '1' : stringNivel += '0';
    verCustoVenda ? stringNivel += '1' : stringNivel += '0';
    autorizarNegociacao ? stringNivel += '1' : stringNivel += '0';
    cancelamentoF3 ? stringNivel += '1' : stringNivel += '0';
    autorizarVendaConsignada ? stringNivel += '1' : stringNivel += '0';
    controleEntrega ? stringNivel += '1' : stringNivel += '0';

    return stringNivel;
  };

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const stringNivel = montarNivel();

      const shippingData = {
        idUsuario,
        email,
        nome,
        senha,
        tipoAcesso,
        nivel: stringNivel,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/operador/${id}`, shippingData), 'data', {});
        toast.success('Operador atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/operador', shippingData), 'data', {});
        toast.success('Operador cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (nome.length < 3 || nome.length > 50) {
      formErros = true;
      toast.error('Nome do usuário deve ter entre 3 a 50 caracteres');
    }
    if (!isEmail(email)) {
      formErros = true;
      toast.error('E-mail inválido.');
    }
    if (!id && senha.length < 3) {
      formErros = true;
      toast.error('Senha deve ter no mínimo 3 caracteres');
    }
    if (!id && senha !== senhaRepeticao) {
      formErros = true;
      toast.error('Senhas digitadas não conferem');
    }
    if (!tipoAcesso) {
      formErros = true;
      toast.error('Tipo de Acesso deve ser informado');
    }

    setNivel(montarNivel());

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Operador - Alteração' : 'Operador - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
          <TabPanel header="Principal">
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Nome
                  </Input155px>
                  <InputText
                    autoFocus
                    maxLength={50}
                    value={nome}
                    onChange={e => setNome(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    E-mail
                  </Input155px>
                  <InputText
                    id="inputEmail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-lg-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Tipo Acesso
                  </Input155px>
                  <Dropdown
                    options={listaTipoAcesso}
                    value={tipoAcesso}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setTipoAcesso(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Senha
                  </Input155px>
                  <Password
                    disabled={id}
                    id="inputSenha"
                    toggleMask
                    weakLabel="Fraca"
                    mediumLabel="Média"
                    strongLabel="Forte"
                    value={senha}
                    onChange={e => setSenha(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Repita a Senha
                  </Input155px>
                  <Password
                    disabled={id}
                    id="inputSenhaRepeticao"
                    toggleMask
                    weakLabel="Fraca"
                    mediumLabel="Média"
                    strongLabel="Forte"
                    value={senhaRepeticao}
                    onChange={e => setSenhaRepeticao(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Frente de Loja Permissões/Restrições">
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Abertura Caixa
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={aberturaCaixa}
                    onChange={(e) => setAberturaCaixa(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Reforço
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={reforco}
                    onChange={(e) => setReforco(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Sangria
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={sangria}
                    onChange={(e) => setSangria(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Acerto Cobrança
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={acertoCobranca}
                    onChange={(e) => setAcertoCobranca(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Alteração Pedido
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={alteracaoPedido}
                    onChange={(e) => setAlteracaoPedido(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Reemissão Pedido
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={reemissaoPedido}
                    onChange={(e) => setReemissaoPedido(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Cancelamento Pedido
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={cancelamentoPedido}
                    onChange={(e) => setCancelamentoPedido(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Tranferência
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={transferencia}
                    onChange={(e) => setTransferencia(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Abrir Gaveta
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={abrirGaveta}
                    onChange={(e) => setAbrirGaveta(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Autorização Venda
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={autorizacaoVenda}
                    onChange={(e) => setAutorizacaoVenda(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Autorização Desconto
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={autorizacaoDesconto}
                    onChange={(e) => setAutorizacaoDesconto(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Devolução
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={devolucao}
                    onChange={(e) => setDevolucao(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Retirar Inadimplencia
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={retirarInadimplencia}
                    onChange={(e) => setRetirarInadimplencia(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Mobile Offline
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={mobileOffline}
                    onChange={(e) => setMobileOffline(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Somente Consulta AC
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={somenteConsultaAC}
                    onChange={(e) => setSomenteConsultaAC(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Ver Custo Venda
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={verCustoVenda}
                    onChange={(e) => setVerCustoVenda(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Autorizar Negociação
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={autorizarNegociacao}
                    onChange={(e) => setAutorizarNegociacao(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Cancelamento Venda (F3)
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={cancelamentoF3}
                    onChange={(e) => setCancelamentoF3(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Autorizar Venda Consignada
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={autorizarVendaConsignada}
                    onChange={(e) => setAutorizarVendaConsignada(e.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Controle Entrega
                  </Input200px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={controleEntrega}
                    onChange={(e) => setControleEntrega(e.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
