import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { get } from 'lodash';
import dateFormat from 'dateformat';

import { fillColumns } from '../../../components/table';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../util/diversos';
import { Input100px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';

export default function ConsultaResumoNfce() {
  const [isLoading, setIsLoading] = useState(false);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [multiEmpresa, setMultiEmpresa] = useState(true);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [columnsSetup] = useState([
    { field: 'data', header: 'Data', sort: false },
    { field: 'totalVenda', header: 'Total', sort: false },
    { field: 'pagoDinheiro', header: 'Dinheiro', sort: false },
    { field: 'pagoCheque', header: 'Cheque', sort: false },
    { field: 'pagoCartaoCredito', header: 'Cartão Crédito', sort: false },
    { field: 'pagoCartaoDebito', header: 'Cartão Débito', sort: false },
    { field: 'pagoDigital', header: 'Digital', sort: false },
    { field: 'pagoBoleto', header: 'Boleto', sort: false },
    { field: 'valorCarteira', header: 'Carteira', sort: false },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, filtrarCnpjEmpresa) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/resumonfce?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');

        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
          setCnpjEmpresa(_cnpjEmpresaStore);
          cnpjPreenchido = true;
        }

        _listaEmpresas.forEach(element => {
          if (!cnpjPreenchido) {
            setCnpjEmpresa(element.cnpj);
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (listDropdown.length > 1) {
          setMultiEmpresa(true);
        } else {
          setMultiEmpresa(false);
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa);
  };

  function handleCnpjEmpresa(e) {
    setCnpjEmpresa(e.target.value);

    dispatch(actions.cnpjEmpresaChange({ cnpjEmpresa: e.target.value }));

    setDataTableList([]);
  }

  function selecaoEmpresa() {
    if (multiEmpresa) {
      return (
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Empresa
              </Input100px>
              <Dropdown
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleCnpjEmpresa(e)}
              />
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="card mb-3">
        <h6 className="card-header" style={{ textAlign: 'center' }}>Resumo Diário de NFC-e</h6>
      </div>
      <Card>
        <div>
          {selecaoEmpresa()}
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial:
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => setFindDataInicial(e.value)}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final:
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => setFindDataFinal(e.value)}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            rows={10}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>
      </Card>
    </>
  );
}
