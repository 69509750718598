/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get, orderBy } from 'lodash';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import { displayErrors } from '../../../util/diversos';

export default function PdfMovimentacaoProduto({ payloadPrint, finishPrintMovimentacaoProduto }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadPrint, 'id', 0);

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  function headRows() {
    return [
      { item: 'item', registro: 'Registro', descricao: 'Descricao', quantidade: 'Quantidade' },
    ];
  }

  const [headerTabelaImpressao] = useState(headRows());

  useEffect(() => {
    async function getData(img) {
      // eslint-disable-next-line new-cap

      try {
        const usuario = await axiosAdmin.get(`/usuario/${idUsuario}`);
        let cnpjEmpresa = usuario.data.listaEmpresas[0].cnpj;
        if (cnpjEmpresa.length === 14) {
          cnpjEmpresa = cnpjEmpresa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

        const { data } = await axiosAdmin.get(`/movimentacaoproduto/${id}`);

        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultamovimentacaoproduto', '');
        }

        let tipoMovimentacao = '';
        if (data.tipo === 'ENTRADAESTOQUE') {
          tipoMovimentacao = 'Entrada Estoque';
        }
        if (data.tipo === 'TRANSFERENCIA') {
          tipoMovimentacao = 'Transferência Estoque';
        }
        if (data.tipo === 'SAIDAACERTOESTOQUE') {
          tipoMovimentacao = 'Saída Acerto Estoque';
        }
        if (data.tipo === 'SAIDACONSUMOPROPRIO') {
          tipoMovimentacao = 'Saída Consumo Próprio';
        }
        if (data.tipo === 'SAIDAFURTO') {
          tipoMovimentacao = 'Saída Furto';
        }
        if (data.tipo === 'SAIDAPERDA') {
          tipoMovimentacao = 'Saída Perda';
        }
        if (data.tipo === 'SAIDAGARANTIA') {
          tipoMovimentacao = 'Saída Garantia';
        }

        const empresaOrigem = data.empresaOrigem ? data.empresaOrigem : null;
        const empresaDestino = data.empresaDestino ? data.empresaDestino : null;

        const result = [];

        let dados = {};
        data.listaMovimentacaoProdutoItem.forEach(el => {
          dados.item = el.item;
          dados.registro = el.produto.registro;
          dados.descricao = el.produto.descricao.trim();
          dados.quantidade = el.quantidade;
          result.push({ ...dados });
        });

        dados = {
          item: '',
          registro: '',
          descricao: '',
          quantidade: '',
        };

        result.push({ ...dados });

        // eslint-disable-next-line new-cap
        const doc = new jsPDF();
        // const tamanhoImpressao = 100 + (15 * result.length);
        // console.log(tamanhoImpressao);
        // const doc = new jsPDF('p', 'mm', [80, tamanhoImpressao]);

        doc.autoTable({
          styles: { fontSize: 10 },
          margin: { bottom: 20, top: 38, left: 5, right: 5 },
          theme: 'grid',
          head: headerTabelaImpressao,
          body: result,
          didDrawPage: (_dados) => {
            // Header
            doc.rect(5, 5, 200, 30);
            if (!img.complete) {
              doc.addImage('logo.png', 'JPEG', 10, 12, 20, 15);
            } else {
              doc.addImage(img, 'JPEG', 9, 8, 25, 25);
            }
            doc.setFontSize(15);
            doc.text('Movimentação Produto: ', 40, 13);
            doc.text(id.toString(), 98, 13);

            doc.text('Tipo: ', 137, 13);
            doc.text(tipoMovimentacao, 150, 13);

            doc.setFontSize(12);
            doc.text('Data: ', 137, 32);
            doc.text(`${('00' + data.data[2]).slice(-2)}/${('00' + data.data[1]).slice(-2)}/${data.data[0]}`, 147, 32);

            if (tipoMovimentacao === 'Entrada Estoque') {
              doc.text('Destino: ', 40, 32);
              doc.text(empresaDestino.nomeFantasia ? empresaDestino.nomeFantasia : empresaDestino.razaoSocial, 58, 32);
            }

            if (tipoMovimentacao === 'Transferência Estoque') {
              doc.text('Origem: ', 40, 26);
              doc.text(empresaOrigem.nomeFantasia ? empresaOrigem.nomeFantasia : empresaOrigem.razaoSocial, 58, 26);
              doc.text('Destino: ', 40, 32);
              doc.text(empresaDestino.nomeFantasia ? empresaDestino.nomeFantasia : empresaDestino.razaoSocial, 58, 32);
            }

            // footer
            doc.setFontSize(8);
            const { pageSize } = doc.internal;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Pag ${doc.internal.getNumberOfPages()}`, _dados.settings.margin.right, pageHeight - 8);
            const periodo = new Date();
            doc.text('Impresso: ', 172, pageHeight - 8);
            // eslint-disable-next-line prefer-template
            doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
          },
        });
        window.open(doc.output('bloburl'));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('erro', err);
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        // funcao no pai pra fechar a tela de impressao
        finishPrintMovimentacaoProduto();
      }
    }

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(img);
    };
    loadImage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, navigate]);

  return (
    <div></div>
  );
}
