import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import dateFormat from 'dateformat';

import * as actions from '../../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../../util/diversos';
import { Input155px, Input100px } from '../../../../styles/inputGroup';

import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Card from '../../../../components/card';
import Loading from '../../../loading';

export default function GerarSintegra() {
  const [isLoading, setIsLoading] = useState(false);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [multiEmpresa, setMultiEmpresa] = useState(true);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [dataInventario, setDataInventario] = useState(null);
  const [tipoProduto, setTipoProduto] = useState('00');
  const [listaTipoProduto, setListaTipoProduto] = useState([]);
  const [codigoConvenio, setCodigoConvenio] = useState('3');
  const [naturezaOperacao, setNaturezaOperacao] = useState('3');
  const [finalidade, setFinalidade] = useState('1');
  const [listaCodigoConvenio] = useState([
    { label: 'Estrutura conforme Convênio ICMS 57/95, na versão estabelecida pelo Convênio ICMS 31/99 e com as alterações promovidas até o Convênio ICMS 30/02', value: '1' },
    { label: 'Estrutura conforme Convênio ICMS 57/95, na versão estabelecida pelo Convênio ICMS 69/02 e com as alterações promovidas pelo Convênio ICMS 142/02', value: '2' },
    { label: 'Estrutura conforme Convênio ICMS 57/95, com as alterações promovidas pelo Convênio ICMS 76/03', value: '3' },
  ]);
  const [listaNaturezaOperacao] = useState([
    { label: 'Interestaduais somente operações sujeitas ao regime de Substituição Tributária', value: '1' },
    { label: 'Interestaduais - operações com ou sem Substituição Tributária', value: '2' },
    { label: 'Totalidade das operações do informante', value: '3' },
  ]);
  const [listaFinalidade] = useState([
    { label: 'Normal', value: '1' },
    { label: 'Retificação total de arquivo: substituição total de informações prestadas pelo contribuinte referentes a este período', value: '2' },
    { label: 'Retificação aditiva de arquivo: acréscimo de informação não incluída em arquivos já apresentados', value: '3' },
    { label: 'Desfazimento: arquivo de informação referente a operações/prestações não efetivadas', value: '5' },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    let mostrarMensagemErro = true;

    async function verificarSeMultiEmpresa(idUsuario) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
          setCnpjEmpresa(_cnpjEmpresaStore);
          cnpjPreenchido = true;
        }

        _listaEmpresas.forEach(element => {
          if (!cnpjPreenchido) {
            setCnpjEmpresa(element.cnpj);
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (listDropdown.length > 1) {
          setMultiEmpresa(true);
        } else {
          setMultiEmpresa(false);
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));

    async function getTipoProduto() {
      try {
        setIsLoading(true);

        const listDropdown = [];
        const { data: _unidadesMedida } = await axiosAdmin.get('/tipoproduto');

        _unidadesMedida.forEach(element => {
          listDropdown.push({ label: `${element.codigo} ${element.descricao}`, value: element.codigo });
        });
        setListaTipoProduto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getTipoProduto();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function handleCnpjEmpresa(e) {
    setCnpjEmpresa(e.target.value);

    dispatch(actions.cnpjEmpresaChange({ cnpjEmpresa: e.target.value }));
  }

  function selecaoEmpresa() {
    if (multiEmpresa) {
      return (
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Empresa
              </Input100px>
              <Dropdown
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleCnpjEmpresa(e)}
              />
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  function download(filename, text) {
    const blob = new Blob([text], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  }

  async function gerarArquivoSintegra() {
    try {
      setIsLoading(true);

      const _dataInicial = dateFormat(findDataInicial, 'dd-mm-yyyy');
      const _dataFinal = dateFormat(findDataFinal, 'dd-mm-yyyy');

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/sintegra?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresa}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;

      if (dataInventario != null) {
        const _dataInventario = dateFormat(dataInventario, 'dd-mm-yyyy');
        params = `${params}&dataInventario=${_dataInventario}&gerarInventario=true`;
      } else {
        params = `${params}&gerarInventario=false`;
      }

      params = `${params}&tipoProduto=${tipoProduto}`;

      params = `${params}&codigoConvenio=${codigoConvenio}&naturezaOperacao=${naturezaOperacao}&finalidade=${finalidade}`;

      const response = await axiosAdmin.get(params);

      let conteudoArquivo = '';
      let sequencialHeader = '0000000';

      response.data.forEach(element => {
        if (sequencialHeader === '0000000') {
          sequencialHeader = element.substring(111, 117);
        }

        conteudoArquivo = `${conteudoArquivo + element}\n`;
      });

      download('Sintegra' + _dataInicial.substring(3, 5) + _dataInicial.substring(6, 10) + '.txt', conteudoArquivo);

      toast.success('Arquivo Sintegra gerado com sucesso');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleGerarArquivo = (e) => {
    e.preventDefault();

    const formErros = false;

    if (formErros) return;

    gerarArquivoSintegra();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="card mb-3">
        <h6 className="card-header" style={{ textAlign: 'center' }}>Gerar Arquivo Sintegra</h6>
      </div>
      <Card>
        <div>
          {selecaoEmpresa()}
          <div className="row form-elo">
            <div className="col-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data inicial:
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => setFindDataInicial(e.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data final:
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => setFindDataFinal(e.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleGerarArquivo}
              >
                <i className="pi pi-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Código Convênio
              </Input155px>
              <Dropdown
                options={listaCodigoConvenio}
                value={codigoConvenio}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setCodigoConvenio(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Inventário:
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataInventario}
                onChange={(e) => setDataInventario(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTipoProduto}
                value={tipoProduto}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setTipoProduto(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Natureza Operações
              </Input155px>
              <Dropdown
                options={listaNaturezaOperacao}
                value={naturezaOperacao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setNaturezaOperacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Finalidade
              </Input155px>
              <Dropdown
                options={listaFinalidade}
                value={finalidade}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setFinalidade(e.target.value)}
              />
            </div>
          </div>
        </div>
        <br />
      </Card>
    </>
  );
}
