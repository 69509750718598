import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import dateFormat from 'dateformat';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';
import CadastroPrevisaoFinanceira from './cadastroPrevisaoFinanceira';

export default function ConsultaPrevisaoFinanceira() {
  const [isLoading, setIsLoading] = useState(false);
  const [showFiltroModal, setShowFiltroModal] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [filtroAtualizado, setFiltroAtualizado] = useState(null);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [findOperacaoFinanceira, setFindOperacaoFinanceira] = useState('PAGAR');
  const [tmpOperacaoFinanceira, setTmpOperacaoFinanceira] = useState('PAGAR');
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});
  const [columnsSetup] = useState([
    { field: 'dataEmissao', header: 'Emissão', sort: true },
    { field: 'centroCusto.codigo', header: 'Centro Custo', sort: false },
    { field: 'dataVigenciaInicial', header: 'Vigência Início', sort: false },
    { field: 'dataVigenciaFinal', header: 'Vigência Fim', sort: false },
    { field: 'diaVencimento', header: 'Dia Mês', sort: false },
    { field: 'diaSemana', header: 'Dia Semana', sort: false },
    { field: 'valorTotal', header: 'Valor Total', sort: false },
  ]);
  const [listaOperacaoFinanceira] = useState([
    { label: 'Pagar', value: 'PAGAR' },
    { label: 'Receber', value: 'RECEBER' },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, filtrarCnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/financeiroprevisao/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');

        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataEmissaoInicial=${_dataInicial}&dataEmissaoFinal=${_dataFinal}&operacao=${findOperacaoFinanceira}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
            setCnpjEmpresa(element.cnpj);
            const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }

        getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    if (filtroAtualizado) {
      setFiltroAtualizado(false);
      handleFind();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filtroAtualizado]);

  const handleRegister = () => {
    const id = 0;

    setPayloadCadastro({ id, cnpjEmpresa, findOperacaoFinanceira });

    setShowCadastro(true);
  };

  const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setPayloadCadastro({ id, cnpjEmpresa, findOperacaoFinanceira });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/financeiroprevisao/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  const onHideFiltroModal = (ok) => {
    setShowFiltroModal(false);
    if (ok) {
      setFindOperacaoFinanceira(tmpOperacaoFinanceira);
      setFiltroAtualizado(true);
    } else {
      setTmpOperacaoFinanceira(findOperacaoFinanceira);
    }
  };

  const renderFooterFiltroModal = () => (
    <div>
      <Button label="Não" icon="pi pi-times" onClick={() => onHideFiltroModal(false)} className="p-button-text" />
      <Button label="Sim" icon="pi pi-check" onClick={() => onHideFiltroModal(true)} autoFocus />
    </div>
  );

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentCadastro = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroPrevisaoFinanceira payloadCadastro={payloadCadastro} childToParentCadastro={childToParentCadastro} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              Previsão Financeira - CONTAS A {findOperacaoFinanceira}
            </h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial
                </Input100px>
                <Calendar
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaPrevisaoFinanceiraDataInicial = e.value;
                    const consultaPrevisaoFinanceiraDataFinal = findDataFinal;
                    dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final
                </Input100px>
                <Calendar
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaPrevisaoFinanceiraDataInicial = findDataInicial;
                    const consultaPrevisaoFinanceiraDataFinal = e.value;
                    dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Empresa
                </Input100px>
                <Dropdown
                  options={listaEmpresas}
                  value={cnpjEmpresa}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => {
                    setCnpjEmpresa(e.target.value);
                    const consultaPrevisaoFinanceiraCnpjEmpresa = e.target.value;
                    dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                title="Filtrar"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={() => setShowFiltroModal(true)}
              >
                Filtro
                <i className="pi pi-filter"></i>
              </button>
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>
          <Dialog
            header="Filtro Financeiro"
            visible={showFiltroModal}
            style={{ width: '98vw' }}
            onHide={() => onHideFiltroModal()}
            footer={renderFooterFiltroModal()}
          >
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Operação
                  </Input100px>
                  <Dropdown
                    options={listaOperacaoFinanceira}
                    value={tmpOperacaoFinanceira}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setTmpOperacaoFinanceira(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
