/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { displayErrors } from '../../../util/diversos';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';

export default function PdfEstampariaOrdemProducao({ payloadPrint, finishPrintOrdemProducao }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadPrint, 'id', 0);

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  function headRows() {
    return [
      { quantidade: 'Qtde', descricao: 'Descrição', tamanho: 'Tamanho', modelo: 'Modelo', malha: 'Malha', cor: 'Cor' },
    ];
  }
  const [headerTabelaImpressao] = useState(headRows());

  useEffect(() => {
    async function getData(img) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      try {
        const { data } = await axiosAdmin.get(`estampariaordemproducao/${id}`);

        const idOrdemProducao = get(data, 'id', 0);
        const idPedido = get(data, 'idEstampariaPedido', 0);

        const pedido = await axiosAdmin.get(`estampariapedido/${idPedido}`);

        const nomeVendedor = get(data.vendedor, 'nome', '');

        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultaestampariaordemproducao', '');
        }

        let dados = {
          quantidade: '0',
          descricao: '',
          modelo: '',
          tamanho: '',
          malha: '',
          cor: '',
        };

        const result = [];
        let itemDetalhes = {};
        let qtdeItens = 0;
        data.listaEstampariaOrdemProducaoItem.forEach(element => {
          // pegar as informacoes do item no pedido
          pedido.data.listaEstampariaPedidoItem.forEach((item) => {
            if (item.item === element.item && item.item > 0) {
              itemDetalhes = item;
              dados.quantidade = item.quantidade.toString();
              qtdeItens += item.quantidade;
              dados.descricao = get(item.produto, 'descricao', '').trim();
              if (item.estampariaModelo) {
                dados.modelo = item.estampariaModelo.descricao;
              }
              if (item.estampariaTamanho) {
                dados.tamanho = item.estampariaTamanho.descricao;
              }
              if (item.estampariaMalha) {
                dados.malha = item.estampariaMalha.descricao;
              }
              if (item.estampariaCor) {
                dados.cor = item.estampariaCor.descricao;
              }
              result.push({ ...dados });
            } // fim do item igual a element
          });
        });

        dados = {
          quantidade: '',
          descricao: '',
          modelo: '',
          tamanho: '',
          malha: '',
          cor: '',
        };
        result.push({ ...dados });

        dados = {
          quantidade: qtdeItens,
          descricao: 'Item(s)',
          modelo: '',
          tamanho: '',
          malha: '',
          cor: '',
        };
        result.push({ ...dados });

        doc.autoTable({
          styles: { fontSize: 11 },
          margin: { top: 47, bottom: 130, left: 5, right: 5 },
          theme: 'grid',
          head: headerTabelaImpressao,
          body: result,
          didDrawPage: (_dados) => {
            // Header
            doc.rect(5, 5, 200, 30);
            if (!img.complete) {
              doc.addImage('logo.png', 'JPEG', 10, 12, 20, 15);
            } else {
              doc.addImage(img, 'JPEG', 9, 8, 25, 25);
            }
            doc.setFontSize(20);
            doc.text('Ordem de Produção ', 75, 15);

            doc.setFontSize(10);
            doc.text('Ordem Produção:', 144, 22);
            doc.text(idOrdemProducao.toString(), 172, 22);

            doc.text('Número Pedido:', 144, 27);
            doc.text(idPedido.toString(), 172, 27);

            doc.text('Vendedor:', 144, 32);
            data.vendedor ? doc.text((data.vendedor.codigo + ' - ' + data.vendedor.nome).substring(0, 20), 161, 32) : '';

            pedido.data.nomePedido ? doc.text(pedido.data.nomePedido.substring(0, 45), 40, 22) : '';
            doc.text('Cliente: ', 40, 27);
            let cliente = '';
            if (pedido.data.cliente) {
              cliente = `${pedido.data.cliente.id} - ${pedido.data.cliente.razaoSocial.substring(0, 45)}`;
              doc.text(cliente, 53, 27);
              doc.text('Telefone: ', 40, 32);
              pedido.data.cliente.telefone_1 ? doc.text(pedido.data.cliente.telefone_1, 60, 32) : '';
            }

            // barra cinza com datas
            doc.setFillColor(232, 232, 232);
            doc.rect(5, 36, 200, 10, 'F');
            doc.setFontSize(16);
            doc.text('Data: ', 7, 43);
            doc.text(new Date(pedido.data.data[0], pedido.data.data[1] - 1, pedido.data.data[2]).toLocaleDateString('pt-br'), 21, 43);
            doc.text('Data Entrega: ', 139, 43);
            pedido.data.dataPrevisaoEntrega ? doc.text(new Date(pedido.data.dataPrevisaoEntrega[0], pedido.data.dataPrevisaoEntrega[1] - 1, pedido.data.dataPrevisaoEntrega[2]).toLocaleDateString('pt-br'), 175, 43) : '';

            doc.setFillColor(232, 232, 232);
            doc.rect(5, 167, 200, 32, 'F');
            const coluna = 7;
            let linha = 171;
            if (itemDetalhes) {
              const item = itemDetalhes;
              doc.setFontSize(8);
              if (item.estampariaGola) {
                const golaDescricao = `${item.estampariaGola.descricao.toUpperCase()}`;
                doc.setFontSize(7);
                doc.text('Gola: '.toUpperCase(), coluna, linha);
                doc.setFontSize(10);
                doc.text(golaDescricao, coluna + 9, linha);

                if (item.estampariaGolaCor) {
                  const golaCor = ` ${item.estampariaGolaCor.descricao.toUpperCase()}\n`;
                  doc.setFontSize(7);
                  doc.text('Gola Cor: '.toUpperCase(), coluna + 92, linha);
                  doc.setFontSize(10);
                  doc.text(golaCor, coluna + 106, linha);
                  linha = linha + 5;
                }
                if (item.estampariaGolaAcabamento) {
                  const golaAcabamento = ` ${item.estampariaGolaAcabamento.descricao.toUpperCase()}`;
                  doc.setFontSize(7);
                  doc.text('Gola Acabamento: '.toUpperCase(), coluna, linha);
                  doc.setFontSize(10);
                  doc.text(golaAcabamento, coluna + 25, linha);
                }
                linha = linha + 5;
              }

              if (item.estampariaManga) {
                const mangaDescricao = `${item.estampariaManga.descricao.toUpperCase()}`;
                doc.setFontSize(7);
                doc.text('Manga: '.toUpperCase(), coluna, linha);
                doc.setFontSize(10);
                doc.text(mangaDescricao, coluna + 10, linha);

                if (item.estampariaMangaCor) {
                  const mangaCor = `${item.estampariaMangaCor.descricao.toUpperCase()}`;
                  doc.setFontSize(7);
                  doc.text('Manga Cor: '.toUpperCase(), coluna + 92, linha);
                  doc.setFontSize(10);
                  doc.text(mangaCor, coluna + 108, linha);
                }
                if (item.estampariaMangaAcabamento) {
                  linha = linha + 5;
                  const mangaAcabamento = ` ${item.estampariaMangaAcabamento.descricao.toUpperCase()}`;
                  doc.setFontSize(7);
                  doc.text('Manga Acabamento: '.toUpperCase(), coluna, linha);
                  doc.setFontSize(10);
                  doc.text(mangaAcabamento, coluna + 27, linha);
                }
                if (item.estampariaMangaAcabamentoCor) {
                  const mangaAcabamentoCor = ` ${item.estampariaMangaAcabamentoCor.descricao.toUpperCase()}`;
                  doc.setFontSize(7);
                  doc.text('Manga Acabamento Cor: '.toUpperCase(), coluna + 92, linha);
                  doc.setFontSize(10);
                  doc.text(mangaAcabamentoCor, coluna + 125, linha);
                }
                linha = linha + 5;
              }

              if (item.estampariaBolso) {
                const bolsoDescricao = `${item.estampariaBolso.descricao.toUpperCase()}`;
                doc.setFontSize(7);
                doc.text('Bolso: '.toUpperCase(), coluna, linha);
                doc.setFontSize(10);
                doc.text(bolsoDescricao, coluna + 11, linha);

                if (item.estampariaBolsoCor) {
                  const bolsoCor = ` ${item.estampariaBolsoCor.descricao.toUpperCase()}`;
                  doc.setFontSize(7);
                  doc.text('Bolso Cor: '.toUpperCase(), coluna + 92, linha);
                  doc.setFontSize(10);
                  doc.text(bolsoCor, coluna + 107, linha);
                }
                linha = linha + 5;
              }

              if (item.listaEstampariaPedidoItemAcessorio.length > 0) {
                let linhaAtual = 1;
                let acessorios = '';
                doc.setFontSize(7);
                doc.text('Acréscimo(s): '.toUpperCase(), coluna, linha);
                item.listaEstampariaPedidoItemAcessorio.forEach(_listaAcessorios => {
                  acessorios += `${_listaAcessorios.estampariaAcessorio.descricao}, `;
                  if (acessorios.length > 80 && linhaAtual === 1) {
                    acessorios += '\n';
                    linhaAtual++;
                  }
                });
                doc.setFontSize(10);
                doc.text(acessorios.slice(0, -1), coluna + 20, linha);
              }

              doc.setFontSize(10);
              for (let x = 0; x < item.listaEstampariaPedidoTipoServico.length; x++) {
                const servico = item.listaEstampariaPedidoTipoServico[x];

                if (servico.parteVestuario === 'FRENTE') {
                  let alturaInicial = 244;
                  if (servico.silkar) {
                    if (servico.silk.descricao && servico.silk.descricao !== undefined) {
                      doc.text(`Silkar: ${servico.silk.descricao}`, 20, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                  if (servico.sublimar) {
                    if (servico.sublimacao.descricao && servico.sublimacao.descricao !== undefined) {
                      doc.text(`Sublimar: ${servico.sublimacao.descricao}`, 20, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }

                  if (servico.bordar) {
                    if (servico.bordado.descricao && servico.bordado.descricao !== undefined) {
                      doc.text(`Bordar: ${servico.bordado.descricao}`, 20, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                }
                if (servico.parteVestuario === 'COSTAS') {
                  let alturaInicial = 244;
                  if (servico.silkar) {
                    if (servico.silk.descricao && servico.silk.descricao !== undefined) {
                      doc.text(`Silkar: ${servico.silk.descricao}`, 118, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                  if (servico.sublimar) {
                    if (servico.sublimacao.descricao && servico.sublimacao.descricao !== undefined) {
                      doc.text(`Sublimar: ${servico.sublimacao.descricao}`, 118, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                  if (servico.bordar) {
                    if (servico.bordado.descricao && servico.bordado.descricao !== undefined) {
                      doc.text(`Bordar: ${servico.bordado.descricao}`, 118, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                }

                if (servico.parteVestuario === 'MANGAESQUERDA') {
                  let alturaInicial = 276;
                  if (servico.silkar) {
                    if (servico.silk.descricao && servico.silk.descricao !== undefined) {
                      doc.text(`Silkar: ${servico.silk.descricao}`, 20, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                  if (servico.sublimar) {
                    if (servico.sublimacao.descricao && servico.sublimacao.descricao !== undefined) {
                      doc.text(`Sublimar: ${servico.sublimacao.descricao}`, 20, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                  if (servico.bordar) {
                    if (servico.bordado.descricao && servico.bordado.descricao !== undefined) {
                      doc.text(`Bordar: ${servico.bordado.descricao}`, 20, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                }
                if (servico.parteVestuario === 'MANGADIREITA') {
                  let alturaInicial = 276;
                  if (servico.silkar) {
                    if (servico.silk.descricao && servico.silk.descricao !== undefined) {
                      doc.text(`Silkar: ${servico.silk.descricao}`, 118, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                  if (servico.sublimar) {
                    if (servico.sublimacao.descricao && servico.sublimacao.descricao !== undefined) {
                      doc.text(`Sublimar: ${servico.sublimacao.descricao}`, 118, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                  if (servico.bordar) {
                    if (servico.bordado.descricao && servico.bordado.descricao !== undefined) {
                      doc.text(`Bordar: ${servico.bordado.descricao}`, 118, alturaInicial);
                    }
                    alturaInicial = alturaInicial + 3.5;
                  }
                }
              }
            }

            doc.setFontSize(8);
            doc.text('Frente', 20, 205);
            doc.addImage('camisaFrente.png', 'JPEG', 35, 205, 35, 35);
            doc.text('______________________________________________', 20, 256);
            doc.text('Costas', 118, 205);
            doc.addImage('camisaCostas.png', 'JPEG', 131, 205, 35, 35);
            doc.text('______________________________________________', 118, 256);

            doc.text('Manga Esquerda', 20, 261);
            doc.rect(20, 262, 72, 10);
            doc.text('______________________________________________', 20, 287);
            doc.text('Manga Direita', 118, 261);
            doc.rect(118, 262, 72, 10);
            doc.text('______________________________________________', 118, 287);

            // footer
            const { pageSize } = doc.internal;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Pag ${doc.internal.getNumberOfPages()}`, _dados.settings.margin.right + 3, pageHeight - 5);
            const periodo = new Date();
            doc.text('Impresso: ', 175, pageHeight - 5);
            // eslint-disable-next-line prefer-template
            doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 5);
          },
        });

        window.open(doc.output('bloburl'));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('erro', err);
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        // funcao no pai pra fechar a tela de impressao
        finishPrintOrdemProducao();
      }
    }

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(img);
    };
    loadImage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, navigate]);

  return (
    <div></div>
  );
}
